// src/components/FlowBuilder/FlowNode.js
import React from 'react';
import { useSelector, useDispatch, connect } from 'react-redux';

import {
  showTooltip,
  hideTooltip
} from 'actions/actions.export';

import nodeCategories from 'configs/config.node-categories';

import {
  nodePluginSpacing,
  nodeHeaderHeight, 
  nodePaddingPerPort,
  nodeWidth,
  calculateNodeHeight,
  customRenderDictionary,
  formatLinkId,
  calculatePluginDropZoneLocation
} from './FlowBuilderUtils';

import './Flow.scss';

const FlowNode = React.memo(({ 
  component_id,
  node,
  nodeType,
  flowNodeLibrary = [],
  nodes = [],
  links = [],
  selected,
  onSelectNode,
  onMouseDown,
  onMouseUp,
  onDragNodeStart,
  onDragNodeEnd,
  onNodeChange,
  onLinkStart,
  onLinkEnd,
  onPlay,
  onShowAdditional,
  showPluginDropZone,
  highlightPluginDropZone,
  inputDrafting,
  outputDrafting,
  interactive = true,
  highlight = false, 
  simplified = false,
  acceptableInputs = [],
  acceptableOutputs = []
  }) => {
  if(!node) return null;

  let { x, y } = node;
  const [isDragging, setIsDragging] = React.useState(false);

  const dispatch = useDispatch();
  const intelReducer = useSelector(state => state.intelReducer);
  const componentReducer = useSelector(state => state.componentReducer);
  const tooltip = useSelector(state => state.tooltip);

  if(!flowNodeLibrary || flowNodeLibrary.length === 0){
    flowNodeLibrary = [];
  }

  const defaultNodeHeight = 50;

  if(!nodeType) return null;

  let inputsToUse = nodeType.inputs;
  let outputsToUse = nodeType.outputs;
  
  const category = nodeCategories.find(c => c.name === nodeType.category) || {};

  let filteredToLinks = links.filter(l => l.to.node_id === node.id);
  let filteredFromLinks = links.filter(l => l.from.node_id === node.id);


  let missingRequiredSettings = false;

  if(nodeType.settings){
    nodeType.settings.forEach(setting => {
      if(!node.settings){
        missingRequiredSettings = true; 
        return;
      }
      if(setting.required && node.settings[setting.name] === undefined){
        missingRequiredSettings = true;
      }
    });
  }


  let areWeShowingAdditional = node.show_additional;
  let areAnyAdditionalConnected = false;
  if(!areWeShowingAdditional){
    // then look through the links and see if any of them are connected to any additional ports

    filteredToLinks.forEach(l => {
      // find the port in the nodeType
      let input = inputsToUse.find(i => i.name === l.to.input);
      if(input && input.additional && !input.hidden){
        areWeShowingAdditional = true;
        areAnyAdditionalConnected = true;
      }
    });

    filteredFromLinks.forEach(l => {
      // find the port in the nodeType
      let output = outputsToUse.find(o => o.name === l.from.output);
      if(output && output.additional && !output.hidden){
        areWeShowingAdditional = true;
        areAnyAdditionalConnected = true;
      }
    });
  }

  // node.show_additional is false, but areWeShowingAdditional is true, pass an update
  if(node.show_additional === false && areAnyAdditionalConnected){
    if(onShowAdditional) onShowAdditional(node.id, true);
  }

  const nodeHeight = calculateNodeHeight(node, flowNodeLibrary, areWeShowingAdditional, areAnyAdditionalConnected) || defaultNodeHeight;

  let hasAdditional = false;
  if(inputsToUse){
    hasAdditional = inputsToUse.find(i => i.additional) !== undefined;
  }
  if(!hasAdditional && outputsToUse){
    hasAdditional = outputsToUse.find(o => o.additional) !== undefined;
  }


  let ready = false;
  let currentlyCached = false;
  let hasAllRequiredInputs = false;

  if(inputsToUse?.length === 0){
    hasAllRequiredInputs = true;
    ready = true;
  } else {
    hasAllRequiredInputs = inputsToUse.filter(i => i.required).every(i => {
      return filteredToLinks.find(l => l.to.input === i.name) !== undefined;
    });

    if(componentReducer.linkCache[component_id] && hasAllRequiredInputs){
      // check if each filteredToLinks has a value in the linkCache
      ready = filteredToLinks.every(l => {
        return componentReducer.linkCache[component_id][formatLinkId(l)] !== undefined;
      });
    }
  } 

  if(componentReducer.nodeOutputCache[component_id]){
    currentlyCached = filteredFromLinks.every(l => {
      return componentReducer.nodeOutputCache[component_id][l.from.node_id + ':' + l.from.output] !== undefined;
    });
  } 

  let dropZoneDimensions = calculatePluginDropZoneLocation(node, flowNodeLibrary, nodes, links);

  // if this node is a plugin, figure out which node its connected to via its plugin_config output
  let isPluginConnected = false;
  if(nodeType.is_plugin){

    // find the link that is connected to the plugin_config output
    let pluginConfigLink = filteredFromLinks.find(l => l.from.output === 'plugin_config');

    if(pluginConfigLink){

      // find the node its connected to
      let connectedNode = nodes.find(n => n.id === pluginConfigLink.to.node_id);
      if(connectedNode){

        isPluginConnected = {
          x: connectedNode.x,
          y: connectedNode.y
        }

        // find out how many other nodes are connected to the same node's plugins input
        let connectedToPluginInput = links.filter(l => l.to.node_id === connectedNode.id && l.to.input === 'plugins');

        let yShift = 0;

        // calculate the height of the connected node

        let connectedNodeHeight = calculateNodeHeight(connectedNode, flowNodeLibrary, connectedNode.show_additional, false);
        yShift += connectedNodeHeight;

        for(var i in connectedToPluginInput){
          let otherNode = nodes.find(n => n.id === connectedToPluginInput[i].from.node_id);
          if(!otherNode) continue;

          yShift += nodePluginSpacing;

          if(otherNode.id === node.id){
            break;
          }

          // calculate the height of the other node
          let otherNodeHeight = calculateNodeHeight(otherNode, flowNodeLibrary, false, false);

          yShift += otherNodeHeight;

        }

        isPluginConnected.y += yShift;

        // if x and y are different than these new values, then we need to update the x and y
        if(x !== isPluginConnected.x || y !== isPluginConnected.y){
          if(onNodeChange){
            onNodeChange({
              node_id: node.id, 
              changes: {
                x: isPluginConnected.x,
                y: isPluginConnected.y
              },
              instant: true
            });
          }
        }

        x = isPluginConnected.x;
        y = isPluginConnected.y;
      }
    }
  }

  
  return (<g 
    transform={`translate(${x}, ${y})`} 
    className={"flow-node " + 
      (isDragging ? " flow-node__dragging" : "") + 
      (selected ? " flow-node__selected" : "") +
      (interactive == false ? " no-pointer-events" : "") + 
      (highlight ? " flow-node__highlight" : "") + 
      (isPluginConnected ? " flow-node__plugin-connected" : "")
    }
    onDoubleClick={e => {
      if(!interactive || simplified) return;

      e.stopPropagation();
      if(onSelectNode) onSelectNode(node.id);
    }}
    onMouseDown={e => {
      if(isPluginConnected) return;
      if(onMouseDown) onMouseDown(e, node.id);
    }}
    onMouseUp={onMouseUp ? onMouseUp : null}
    >
      

      {
        (nodeType.accepts_plugins) &&
        <g transform={"translate(0, " + (nodeHeight) + ")"} className={"flow-node-plugin-dropzone-container " + (showPluginDropZone ? "flow-node-plugin-dropzone-container__show" : "")}>
          {/* <rect x={pluginStartPad} y={showPluginDropZone ? 0 : -20} width={nodeWidth - pluginEndPad - pluginStartPad} height={showPluginDropZone ? notchHeight : 0} className="flow-node-plugin-dropzone-link"/> */}
          <rect x={dropZoneDimensions.rel_x} y={showPluginDropZone ? dropZoneDimensions.rel_y : -20} width={dropZoneDimensions.width} height={showPluginDropZone ? dropZoneDimensions.height : 0} className={"flow-node-plugin-dropzone " + (highlightPluginDropZone ? "flow-node-plugin-dropzone__highlight" : "")}/>
          <text
            x={nodeWidth / 2}
            y={0}
            transform={"translate(0, " + (showPluginDropZone ? (dropZoneDimensions.rel_y + dropZoneDimensions.height / 2 - 2) : -20) + ")"}
            textAnchor="middle"
            className="flow-node-plugin-dropzone-label"
          >
            Give this LLM more functionality
          </text>
        </g>
      }
      
      {/* for request nodes, add a bar on the left side that extends beyond the rect */}
      {
        nodeType.is_input && 
          <rect 
            x={-7} 
            y={0} 
            width={15} 
            height={nodeHeight} 
            rx={5}
            ry={5}
            className={"flow-node-request-bar"}/> 
      }

      {/* for output nodes, add a bar on the right side that extends beyond the rect */}
      {
        nodeType.is_output && 
          <rect 
            x={nodeWidth - 5}
            y={0} 
            width={12} 
            height={nodeHeight} 
            rx={5}
            ry={5}
            className={"flow-node-request-bar"}/> 
      }

      {/* background */}
      <rect x={0} y={0} width={nodeWidth} height={nodeHeight} className="flow-node-background" />
      
      
      {/* If theres a missing setting error, lets put a triangle centered above the node in a foreign object */}
      {
        (missingRequiredSettings && interactive) && <foreignObject x={0} y={-30} width={nodeWidth} height={30}>
          <div className="flow-node-missing-settings-error">
            <div>
              <i className="fas fa-exclamation-triangle icon-before-text"></i>Missing Required Settings
            </div>
          </div>
        </foreignObject>
      }

      
      {/* header as foreign object */}
      <foreignObject 
        x={0} 
        y={0} 
        width={nodeWidth} 
        height={nodeHeaderHeight} 
        className={`flow-node-label flow-node-label__${nodeType.category}` + 
        (interactive == false || isPluginConnected ? " flow-node-label__no-interaction" : "")
      }
        onMouseDown={e => {
          // this needs to pass a dragging state to the parent component
          if(onDragNodeStart) onDragNodeStart(e, node.id);
          if(tooltip.show){
            dispatch(hideTooltip());
          }
          setIsDragging(true);
        }}
        onMouseUp={e => {
          e.stopPropagation();
          if(onDragNodeEnd) onDragNodeEnd(e, node.id);
          setIsDragging(false);
        }}
        onMouseEnter={e =>{
          if(isDragging) return
          dispatch(showTooltip({
            el: e.target,
            nobr: false,
            position: 'top',
            lag: 1000,
            content: <div style={{minWidth: 250, maxWidth: 250}} className="">
              <h5 className="no-margin">{nodeType.display_name}</h5>
              <p className="thin-line-height text-400 no-margin-bottom margin-top-05rem">
                <small>
                  {nodeType.description}
                </small>
              </p>
            </div>
          }))
        }}
        onMouseLeave={e => {
          dispatch(hideTooltip());
        }}
        >
          {
            !simplified && 
            <div className="flex-split">
              <div className="text-ellipsis-1-lines">
                <span>
                  <i className={"flow-node-label-icon fal fa-fw fa-" + category.icon}></i> <span className="flow-node-label-text">{nodeType.display_name || ""}</span>
                </span>
              </div>
              {
                interactive &&
                
                <div className="list-right list-right-no-wrap">
                  {
                    (!nodeType.is_static && ready) && 
                    <div className={"flow-node-label-button-icon "}  onClick={e => {
                      if(onPlay) onPlay(node.id);
                    }}>
                      {currentlyCached ? <i className="far fa-sync"/> : <i className="far fa-play"/>}
                    </div>
                  }
                  
                  {nodeType.is_plugin && !isPluginConnected ? <small className="flow-node-label-button-icon fal fa-puzzle-piece text-muted"/> : isPluginConnected ? <small className="flow-node-label-button-icon fas fa-puzzle-piece text-primary"/> : "" }
                  
                  <div className={"flow-node-label-button-icon " + (missingRequiredSettings ? "flow-node-label-button-icon__error" : "")} 
                    onMouseDown={e => {
                      e.stopPropagation();
                    }} 
                    onMouseUp={e => {
                      e.stopPropagation();
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      onSelectNode(node.id);
                    }}>
                      <i className="far fa-cog"></i>
                  </div>
                </div>
              }
            </div>
          }
      </foreignObject>


      


      {/* custom renderer */}
      {
        (nodeType && customRenderDictionary[nodeType.name]) && 
        <foreignObject x={0} y={nodeHeaderHeight} width={nodeWidth} height={nodeType.custom_render_height}>
          <div className="flow-node-custom-render">
            {
              customRenderDictionary[nodeType.name]({
                node, 
                type: nodeType, 
                interactive: true, 
                component_id: component_id,
                from_links: filteredFromLinks,
                to_links: filteredToLinks,
                setNodeSettings: (id, settings) => {

                // pass this change up to the parent component
                if(onNodeChange){
                  onNodeChange({
                    node_id: id, 
                    changes: {
                      settings: {
                        ...node.settings,
                        ...settings
                      }
                    }});
                }

              }})
            }
          </div>
        </foreignObject>
      }

      {/* put a arrow button that is clickable across the width of the node to showAdditional */}
      {
        (hasAdditional && !simplified && !areAnyAdditionalConnected) && 
        <foreignObject
          x={0}
          y={nodeHeight - 20}
          width={nodeWidth}
          height={20}
          className="flow-node-show-additional"  
          onClick={e => {
            if(onShowAdditional) onShowAdditional(node.id, !node.show_additional);
          }}>
            <div className="flow-node-show-additional-button">
            <i className={"fal fa-fw " + (node.show_additional ? "fa-angle-double-up" : "fa-angle-double-down")}></i>
            </div>
        </foreignObject>
      }

      {/* border rect on top */}
      <rect x={0} y={0} width={nodeWidth} height={nodeHeight} className={"flow-node-border " + ((missingRequiredSettings && interactive) ? " flow-node-border__error" : "") }/>

      {/* circle input ports with text labels */}
      {
        (inputsToUse && !simplified) && inputsToUse.map((input, i) => {
          if(input.hidden) return null;
          if(input.additional && !areWeShowingAdditional) return null;

          let y = nodeHeaderHeight + nodePaddingPerPort + i * nodePaddingPerPort;

          if(nodeType.custom_render_height){
            y += nodeType.custom_render_height;
          }

          let requiredAndMissing = false;
          let connected = filteredToLinks.find(l => l.to.input === input.name);

          if(!input.optional && interactive){            
            if(connected === undefined){
              requiredAndMissing = true;
            }
          }


          let isAcceptable = false;
          let isUnaccceptable = false;

          if(acceptableInputs.find(a => a.input.name === input.name)){
            isAcceptable = true;
          } else if(acceptableInputs.length > 0 || acceptableOutputs.length > 0){
            isUnaccceptable = true;
          }

          let typeSplit = input.type.split(' or ');

          return <g transform={`translate(0, ${y})`} key={i} 
            onMouseUp={e => {
              if(onLinkEnd) onLinkEnd(e, node.id, input.name, 'inputs');
            }}>
            <circle 
              className={"flow-node-port " + 
                (inputDrafting == input.name ? "flow-node-port__drafting" : "") + 
                (connected ? " flow-node-port__connected" : "") + 
                (isAcceptable ? " flow-node-port__acceptable" : "") +
                (isUnaccceptable ? " flow-node-port__unacceptable" : "")
              }
              onMouseEnter={e => {
                dispatch(showTooltip({
                    el: e.target,
                    nobr: false,
                    position: 'left',
                    lag: 1000,
                    content: <div style={{minWidth: 250, maxWidth: 250}} className="">
                      <div className="flex-split">
                        <h5 className="no-margin">
                          {input.display_name} {(input.allow_multiple && !input.destructive) && "+*"} {(input.allow_multiple && input.destructive) && "+"}
                        </h5>
                      </div>
                      <div className="list-left margin-top-1rem margin-bottom-1rem">
                        {
                          typeSplit.map((type, ti) => {
                            return <span className="text-tag text-tag-tiny " key={ti}>
                              {type}
                            </span>
                          })
                        }
                      </div>
                      <p className="thin-line-height text-400 margin-top-05rem">
                        <small>
                          {input.description}
                        </small>
                      </p>
                      {
                        input.default !== undefined && <p className="no-margin-bottom">
                          <small>Default Value: </small> 
                          <small className="text-400">
                            {JSON.stringify(input.default)}
                          </small>
                        </p>
                      }
                      {
                        input.allow_multiple && <hr className="hr-mini"/>
                      }

                      {       
                        (input.allow_multiple && !input.destructive) && <p className="thin-line-height no-margin">
                          <small className="text-400">
                            The <strong>+*</strong> means multiple connections can be made, but this node will wait for all of them to be ready before processing them together.
                          </small>
                        </p>
                      }
                      {
                        input.destructive && <p className="thin-line-height no-margin">
                          <small className="text-400">
                            The <strong>+</strong> means multiple connections can be made, but this node will process each connection individually as data comes in.
                          </small>
                        </p>
                      }
                      {
                        isPluginConnected && !connected && <p className="thin-line-height no-margin">
                          <small className="text-400">
                            Since this node is connected as a plugin to an LLM, any empty inputs will be filled in by the LLM when it chooses to run this node.
                          </small>
                        </p>
                      }
                    </div>
                }))
              }}
              onMouseLeave={e => {
                dispatch(hideTooltip())
              }}
              onMouseDown={e => {
                if(onLinkStart) onLinkStart(e, node.id, input.name, 'inputs');
              }}
              />
            <text x={12} y={4} className={"flow-node-port-label " + ((requiredAndMissing && !isPluginConnected) ? "flow-node-port-label-required-error" : "") + (isPluginConnected && !connected ? " flow-node-port-label__plugin-connected" : "")} textAnchor="start">
              {input.display_name} {(input.allow_multiple && !input.destructive) && "+*"} {(input.allow_multiple && input.destructive) && "+"}
              </text>
          </g>
        })
      }

      {/* circle output ports with text labels */}
      {
        (outputsToUse && !simplified) && outputsToUse.map((output, i) => {
          if(output.hidden) return null;
          if(output.additional && !areWeShowingAdditional) return null;

          let y = nodeHeaderHeight + nodePaddingPerPort + i * nodePaddingPerPort;
          if(nodeType.custom_render_height){
            y += nodeType.custom_render_height;
          }

          let connected = filteredFromLinks.find(l => l.from.output === output.name);

          let isAcceptable = false;
          let isUnaccceptable = false;

          if(acceptableOutputs.find(a => a.input.name === input.name)){
            isAcceptable = true;
          } else if(acceptableInputs.length > 0 || acceptableOutputs.length > 0){
            isUnaccceptable = true;
          }

          let typeSplit = output.type.split(' or ');

          return <g transform={`translate(${nodeWidth}, ${y})`} key={i} 
            onMouseUp={e => {
              if(onLinkEnd) onLinkEnd(e, node.id, output.name, 'outputs');
            }}>
            <circle 
              className={"flow-node-port " + 
                (outputDrafting == output.name ? "flow-node-port__drafting" : "") +
                (connected ? " flow-node-port__connected" : "") +
                (isAcceptable ? " flow-node-port__acceptable" : "") +
                (isUnaccceptable ? " flow-node-port__unacceptable" : "")
              } 
              onMouseEnter={e => {
                dispatch(showTooltip({
                    el: e.target,
                    nobr: false,
                    position: 'right',
                    lag: 1000,
                    content: <div style={{minWidth: 250, maxWidth: 250}} className="">
                      <div className="flex-split">
                        <h5 className="no-margin">
                          {output.display_name}
                        </h5>
                      </div>
                      <div className="list-left margin-top-1rem margin-bottom-1rem">
                        {
                          typeSplit.map((type, ti) => {
                            return <span className="text-tag text-tag-tiny " key={ti}>
                              {type}
                            </span>
                          })
                        }
                      </div>
                      <p className="thin-line-height text-400 margin-top-05rem no-margin-bottom">
                        <small>
                          {output.description}
                        </small>
                      </p>
                      {
                        output.default !== undefined && <p>
                          <small>Default Value:</small> 
                          <small className="text-400">
                            {output.default}
                          </small>
                        </p>
                      }
                    </div>
                }))
              }}
              onMouseLeave={e => {
                dispatch(hideTooltip())
              }}
              onMouseDown={e => {
                if(onLinkStart) onLinkStart(e, node.id, output.name, 'outputs');
              }}
              />
            <text x={-12} y={4} className="flow-node-port-label" textAnchor="end">{output.display_name}</text>
          </g>
        })
      }

      
    </g>
  );
});

export default FlowNode;
