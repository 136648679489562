import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import CustomSelect from 'kit/components/CustomSelect/CustomSelect.js'
import CustomField from 'kit/components/CustomField/CustomField'
import CustomButton from 'kit/components/CustomButton/CustomButton'
import Callout from 'kit/components/Callout/Callout.js'
import Modal from 'kit/components/Modal/Modal.js'
import Checkbox from 'kit/components/Checkbox/Checkbox.js'

import MemberSearchSelect from 'components/MemberSearchSelect/MemberSearchSelect.js'
import Hydrate from 'components/Hydrate/Hydrate'

import {
  tryToSetComponentSettings,
  tryToDeleteComponent,
  tryToDeleteInstalledComponent,
  tryToAddTeamMember,
  tryToGetTeam
} from 'actions/actions.export'

import './ComponentSettings.scss';

class ComponentSettings extends Component {
  constructor(props){
    super(props);

    this.state = {
      display_name: "",
      description: "",
      visibility: "org",
      editability: "team",
      demoability: "public",
      team: [],
      other_projects: false,
      retypeNameDelete: "",
      demo_code: "",
      require_demo_code: false,
      allowed_demo_versions: [],
      show_demo_creator: false,
      show_demo_org: false,
      show_demo_title: false,
      type: undefined
    }
  }

  componentWillMount(){
    const { dispatch, componentReducer } = this.props;

    const component = componentReducer.cache[this.props.id];
    if(component){
      
      this.setState({
        display_name: component.display_name,
        description: component.description,
        visibility: component.visibility || 'org',
        editability: component.editability || 'team',
        demoability: component.demoability || 'public',
        other_projects: component.other_projects,
        demo_code: component.demo_code,
        require_demo_code: component.require_demo_code,
        allowed_demo_versions: component.allowed_demo_versions,
        show_demo_creator: component.show_demo_creator,
        show_demo_org: component.show_demo_org,
        show_demo_title: component.show_demo_title,
        type: component.type,
        initialLoad: true
      })


      dispatch(tryToGetTeam({
        kind: 'components',
        id: this.props.id
      }))
    } 
  }

  componentWillReceiveProps(newprops){
    const component = newprops.componentReducer.cache[newprops.id];
    if(component && !this.state.initialLoad){
      if(!newprops.componentReducer.tryingToSetComponentSettings){
        this.setState({
          display_name: component.display_name,
          description: component.description,
          visibility: component.visibility || 'org',
          editability: component.editability || 'team',
          demoability: component.demoability || 'public',
          other_projects: component.other_projects,
          demo_code: component.demo_code,
          require_demo_code: component.demo_code ? true : false,
          allowed_demo_versions: component.allowed_demo_versions,
          show_demo_creator: component.show_demo_creator,
          show_demo_org: component.show_demo_org,
          show_demo_title: component.show_demo_title,
          type: component.type,
          initialLoad: true
        })
      }
    } 

    // do we have this team?
    if(newprops.memberReducer.cache['components'][newprops.id]){
      if(this.state.team.length === 0 && !newprops.memberReducer.tryingToGetTeam){

        let loadedTeam = newprops.memberReducer.cache['components'][newprops.id];

        // our old team method used individual "roles" names for each tool. now we are shifting to a single role name field, so we need a safe parser/translator
        
        loadedTeam.forEach((t)=>{
          if(!t.role){
            t.role = 'read';

            if(t.roles){
              if(t.roles['settings']){
                t.role = 'admin'
              }
              if(t.roles['instructions']){
                t.role = 'write'
              }
            }
          }

          delete t.roles;
        });

        this.setState({
          team: loadedTeam
        })
      }
    }
  }

  render(){

    const { dispatch, componentReducer, projectReducer, memberReducer, userReducer, orgReducer, canWrite } = this.props;

    const component = componentReducer.cache[this.props.id];

    if(!component) return <span>Failed to load component.</span>;

    const team = memberReducer.cache['components'][this.props.id];

    let canSubmit = this.state.display_name && !this.state.display_name_error && !this.state.description_error;

    // figure out if we are in a project view or just a component view
  
    // does the current pathname have '/projects/' in it?
    let inProject = window.location.pathname.indexOf('/projects/') !== -1;
    let project;
    if(inProject){
      // if so, we are in a project view, find the ID by grabbing the next segment of the URL
      var project_id = window.location.pathname.split('/projects/')[1].split('/')[0];
      project = projectReducer.cache[project_id];
    }

    return <div>
      <div className="box">
      <div className="">
          <div className="row">
            
            <div className="col-xl-3">
              <h5 className="no-margin">General Info</h5>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  Give your flow a name and description. This is how it will be referred to throughout ZeroWidth and can help let others know what it does.
                </small>
              </p>
              <small className="">
                Created by <span className="text-900"><Hydrate type="user" id={component.created_by}/></span><br/>
                Created at <span className="text-900">{new Date(component.created_at).toLocaleString()}</span>
              </small>
            </div>
            <div className="col-xl-9">
             
                  
              <CustomField
                name="display_name"
                label="Name"
                placeholder="ex: Assistant, Data Processor"
                description="What do you call this?"
                maxLength={64}
                required={true}
                disabled={componentReducer.tryingToSetComponentSettings || !canWrite}
                value={this.state.display_name}
                serverError={componentReducer.setComponentSettingsForm.errors.display_name}
                lastSubmit={componentReducer.setComponentSettingsForm.lastSubmit.display_name}
                onChange={(e) => {
                  this.setState({
                    display_name: e.value,
                    display_name_error: e.error
                  })
                }}
                />


              <CustomField
                name="description"
                label="Description"
                placeholder="ex: A conversational guide through our training materials."
                description="A few sentences about this agent."
                maxLength={256}
                rows={8}
                disabled={componentReducer.tryingToSetComponentSettings || !canWrite}
                value={this.state.description}
                serverError={componentReducer.setComponentSettingsForm.errors.description}
                lastSubmit={componentReducer.setComponentSettingsForm.lastSubmit.description}
                onChange={(e) => {
                  this.setState({
                    description: e.value,
                    description_error: e.error
                  })
                }}
                />
            </div>
          </div>
          
        

          <div className="row">
            <div className="col-xl-12">
              <hr className=""/>
            </div>
            <div className="col-xl-3">
              <h5 className="no-margin">General Access</h5>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  Manage the overall access to this flow within this project, organization, and beyond.
                </small>
              </p>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  This organization's administrators will always be able to see and manage content within this organization.
                </small>
              </p>
            </div>

            <div className="col-xl-9">

              <div className="row margin-bottom-2rem margin-top-2rem">
                <div className="col-lg-6 padding-top-1rem">
                  <span className="text-900">
                    Who can edit this flow?
                  </span>
                </div>
                <div className="col-lg-6">
                  <CustomSelect
                    inline={true}
                    required={true}
                    disabled={!canWrite || this.state.visibility === 'team' || componentReducer.tryingToSetComponentSettings || this.state.visibility === 'creator'}
                    serverError={componentReducer.setComponentSettingsForm.errors.editability}
                    lastSubmit={componentReducer.setComponentSettingsForm.lastSubmit.editability}
                    value={this.state.editability}
                    onChange={(v)=>{

                      let other_projects = this.state.other_projects;

                      if(v === 'team'){
                        other_projects = false;
                      }

                      this.setState({
                        editability: v,
                        submitted: false
                      })
                    }}
                    options={[
                      // {
                      //   label: <span><i className="far fa-fw fa-globe icon-before-text"/> <span className="text-900">Anyone on the Internet</span></span>,
                      //   value: "public"
                      // },
                      {
                        label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                        value: "org"
                      },
                      {
                        label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                        value: "team"
                      }

                    ]}
                    />
                </div>
              </div>
              <div className="row margin-bottom-2rem margin-top-2rem">
                <div className="col-lg-6 padding-top-1rem">
                  <span className="text-900 ">
                    Who can access & use this flow's demo interface?
                  </span>
                </div>
                <div className="col-lg-6">
                  <CustomSelect
                    inline={true}
                    required={true}
                    disabled={!canWrite || componentReducer.tryingToSetComponentSettings}
                    serverError={componentReducer.setComponentSettingsForm.errors.demoability}
                    lastSubmit={componentReducer.setComponentSettingsForm.lastSubmit.demoability}
                    value={this.state.demoability}
                    onChange={(v)=>{
                      this.setState({
                        demoability: v,
                        submitted: false
                      })
                    }}
                    options={[
                      { 
                        label: <span><i className="far fa-fw fa-globe icon-before-text text-success"/> <span className="text-900">Anyone on the Internet</span></span>,
                        value: "public"
                      },
                      {
                        label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                        value: "org"
                      },
                      {
                        label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                        value: "team"
                      }
                    ]}
                    />
                </div>
              </div>

              <div className="row margin-bottom-2rem margin-top-2rem">
                <div className="col-lg-6 padding-top-1rem">
                  <span className="text-900 ">
                    Who can view this flow and use it as a template?
                  </span><br/>
                  {
                    this.state.visibility === 'public' ?
                    <small className="text-danger">Once published, this flow and all details will be publicly visible and useable via <Link to={"/flow/" + component.id} target="_blank" className="link-no-decoration">zerowidth.ai/flow/{component.id}</Link></small>
                    :
                    <small>
                      This flow will not have a publicly accessible page.
                    </small>
                  }
                </div>
                <div className="col-lg-6">
                  <CustomSelect
                    inline={true}
                    required={true}
                    disabled={!canWrite || componentReducer.tryingToSetComponentSettings}
                    serverError={componentReducer.setComponentSettingsForm.errors.visibility}
                    lastSubmit={componentReducer.setComponentSettingsForm.lastSubmit.visibility}
                    value={this.state.visibility}
                    onChange={(v)=>{

                      let editability = this.state.editability;

                      if(v === 'team'){
                        editability = 'team';
                      }

                      this.setState({
                        visibility: v,
                        editability: editability,
                        submitted: false
                      })
                    }}
                    options={[
                      { 
                        label: <span><i className="far fa-fw fa-globe icon-before-text text-success"/> <span className="text-900">Anyone on the Internet</span></span>,
                        value: "public"
                      },
                      {
                        label: <span><i className="far fa-fw fa-building icon-before-text"/> <span className="text-900">Anyone in this organization</span></span>,
                        value: "org"
                      },
                      {
                        label: <span><i className="far fa-fw fa-users icon-before-text text-warning"/> <span className="text-900">Only specific people</span></span>,
                        value: "team"
                      }
                    ]}
                    />
                </div>
              </div>
              


              {/* <div className="row margin-bottom-2rem margin-top-2rem">
                <div className="col-lg-6 padding-top-1rem">
                  <span className="text-900">
                    Can this flow be added to projects?
                  </span>
                </div>
                <div className="col-lg-6">
                  <CustomSelect
                    inline={true}
                    required={true}
                    disabled={!canWrite || this.state.visibility === 'team' || this.state.editability === 'team' || componentReducer.tryingToSetComponentSettings}
                    serverError={componentReducer.setComponentSettingsForm.errors.other_projects}
                    lastSubmit={componentReducer.setComponentSettingsForm.lastSubmit.other_projects}
                    value={this.state.other_projects}
                    onChange={(v)=>{
                      this.setState({
                        other_projects: v,
                        submitted: false
                      })
                    }}
                    options={[
                      {
                        label: <span><i className="far fa-fw fa-check icon-before-text text-success"/> <span className="text-900">Yes</span></span>,
                        value: true
                      },
                      {
                        label: <span><i className="far fa-fw fa-times icon-before-text text-danger"/> <span className="text-900">No</span></span>,
                        value: false
                      }
                    ]}
                    />
                </div>
              </div> */}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <hr className=""/>
            </div>
            <div className="col-xl-3">
              <h5 className="no-margin">Specific Access & Administrators</h5>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  Manage the specific access to this flow within your organization and who can manage it.
                </small>
              </p>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  This organization's administrators will always be able to see and manage content within this organization, but here you can add specific people with different roles.
                </small>
              </p>
            </div>

            <div className="col-xl-9">
              <div className="flex-split margin-bottom-2rem">
                <div className="flex-grow" key={this.state.team.length}>
                  <MemberSearchSelect 
                    id={component.scope}
                    placeholder="Find members in your organization by their name or email address..."
                    inline={true}
                    removeList={this.state.team}
                    value={this.state.searchedMember}
                    disabled={!canWrite}
                    onChange={(member)=>{
                        
                      let newTeam = this.state.team;
                      newTeam.push({
                        user_id: member.user_id,
                        role: 'read'
                      });

                      this.setState({
                        searchedMember: undefined,
                        team: newTeam
                      })
                    }}
                    onClear={()=>{
                      this.setState({
                        searchedMember: undefined
                      })
                    }}
                    />
                </div>
              </div>
              <div>
                <div className="row ">
                  <div className="col-sm-6">Team Member</div>
                  <div className="col-sm-6">Role</div>
                </div>
                <hr className="hr-mini"/>
                {
                  (this.state.team || []).map((m, i)=>{
                    
                    return <div key={i} className="row margin-bottom-1rem">
                      <div className="col-sm-6">
                        <Hydrate type="user" mode="lockup" id={m.user_id}/>
                      </div>
                      <div className="col-sm-6">
                        <CustomSelect
                          inline={true}
                          disabled={!canWrite || componentReducer.tryingToSetComponentSettings}
                          value={m.role}
                          options={[
                            {
                              label: <span><i className="far fa-fw fa-eye icon-before-text"/> <span className="text-900">Viewer</span></span>,
                              value: 'read'
                            },
                            {
                              label: <span><i className="far fa-fw fa-pencil icon-before-text"/> <span className="text-900">Editor</span></span>,
                              value: 'write'
                            },
                            {
                              label: <span><i className="far fa-fw fa-cog icon-before-text"/> <span className="text-900">Admin</span></span>,
                              value: 'admin'
                            },
                            {
                              label: <span><i className="far fa-fw fa-trash icon-before-text text-danger"/> <span className="text-900">Remove</span></span>,
                              value: 'remove'
                            }
                          ]}
                          onChange={(v)=>{
                            let updatedTeam = this.state.team;
                            if(v === 'remove'){
                              updatedTeam = updatedTeam.filter((t)=>{
                                return t.user_id !== m.user_id
                              })
                            } else {
                              updatedTeam = updatedTeam.map((t)=>{
                                if(t.user_id === m.user_id){
                                  t.role = v;
                                }
                                return t;
                              })
                            }

                            this.setState({
                              team: updatedTeam
                            })
                          }}
                          />
                      </div>
                    </div>
                  })
                }
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12">
              <hr className=""/>
            </div>
            <div className="col-xl-3">
              <h5 className="no-margin">Demo Page</h5>
              <p className="thin-line-height margin-top-05rem padding-right-2rem">
                <small>
                  Configure the public demo interface for this flow. This will allow anyone to interact with your flow via a simple web interface.
                </small>
              </p>
            </div>
            <div className="col-xl-9">
              <div className="row margin-bottom-1rem">
                <div className="col-lg-6 padding-top-1rem">
                  <span className="text-900">
                    Which versions can be demoed?
                  </span>
                </div>
                <div className="col-lg-6">
                  <CustomSelect
                    name="allowed_demo_versions"
                    inline={true}
                    placeholder="Leave blank to disable entirely"
                    options={['All Versions'].concat(component.versions.map((v, i)=>{
                      return v.id
                    }))}
                    multiSelect={true}
                    disabled={componentReducer.tryingToSetComponentSettings || !canWrite}
                    value={this.state.allowed_demo_versions}
                    serverError={componentReducer.setComponentSettingsForm.errors.allowed_demo_versions}
                    lastSubmit={componentReducer.setComponentSettingsForm.lastSubmit.allowed_demo_versions}
                    onChange={(e) => {
                      this.setState({
                        allowed_demo_versions: e
                      })
                    }}
                    />
                </div>
              </div>

              <div className="row margin-bottom-1rem">
                <div className="col-lg-6 padding-top-1rem">
                  <span className="text-900">
                    Protect demo with a passcode?
                  </span>
                </div>
                <div className="col-lg-6">
                  <CustomField
                    name="demo_code"
                    inline={true}
                    placeholder="Leave blank to not require a code"
                    maxLength={24}
                    disabled={componentReducer.tryingToSetComponentSettings || !canWrite || this.state.allowed_demo_versions.length == 0}
                    value={this.state.demo_code}
                    serverError={componentReducer.setComponentSettingsForm.errors.demo_code}
                    lastSubmit={componentReducer.setComponentSettingsForm.lastSubmit.demo_code}
                    onChange={(e) => {
                      this.setState({
                        demo_code: e.value,
                        demo_code_error: e.error,
                        require_demo_code: e.value ? true : false
                      })
                    }}
                    />
                </div>
              </div>
              
              { 
                !component.flow_mode && <div className="row margin-bottom-1rem">
                  <div className="col-lg-6 padding-top-1rem">
                    <span className="text-900">
                      Which interface should be shown?
                    </span>
                  </div>
                  <div className="col-lg-6">
                    <CustomSelect
                      name="type"
                      inline={true}
                      placeholder="Conversational, Single Response"
                      disabled={this.state.allowed_demo_versions.length == 0 || componentReducer.tryingToSetComponentSettings || !canWrite}
                      value={this.state.type}
                      serverError={componentReducer.setComponentSettingsForm.errors.type}
                      lastSubmit={componentReducer.setComponentSettingsForm.lastSubmit.type}
                      options={[
                        {
                          label: "Conversational",
                          value: "chat"
                        },
                        {
                          label: "Single Response",
                          value: "madlib"
                        }
                      ]}
                      onChange={(e) => {
                        this.setState({
                          type: e
                        })
                      }}
                      />
                  </div>
                </div>
                }
            </div>
          </div>


          

          <div className="row margin-top-3rem">
            <div className="flex-split">
              <div/>
              <div className="list-right">
              <CustomButton 
                display={"Save Changes"}
                thinking={componentReducer.tryingToSetComponentSettings}
                success={componentReducer.setComponentSettingsSuccess}
                fail={componentReducer.setComponentSettingsFail}
                disabled={!canWrite || !canSubmit || !canWrite}
                color="success"
                size=""
                onClick={()=>{
                  dispatch(tryToSetComponentSettings({
                    id: component.id,
                    display_name: this.state.display_name,
                    description: this.state.description,
                    visibility: this.state.visibility,
                    editability: this.state.editability,
                    demoability: this.state.demoability,
                    other_projects: this.state.other_projects,
                    demo_code: this.state.demo_code,
                    require_demo_code: this.state.demo_code ? true : false,
                    allowed_demo_versions: this.state.allowed_demo_versions,
                    show_demo_org: this.state.show_demo_org,
                    show_demo_title: this.state.show_demo_title,
                    show_demo_creator: this.state.show_demo_creator,
                    demo_animated_text: this.state.demo_animated_text,
                    type: this.state.type,
                    team: this.state.team
                  }))

                  this.setState({
                    submitted: true
                  })
                }}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
      
      {
        project &&
        
        <div className="box box-warning">
          <div className="row">
            <div className="col-xl-12">
              <h4 className="no-margin">Detach Flow</h4>
              <hr className="hr-mini"/>
            </div>
            <div className="col-xl-3">
              <p className="thin-line-height">
                <small>
                  Unlink this flow from this project.
                </small>
              </p>
              
            </div>
            <div className="col-xl-9">

              <p>
                This will detach this flow from this project and immediately disable its use via this project's API endpoints. It can be re-added and will still be available for use in other projects if it's visibility settings allow. Removing this flow from this project will not delete it from the organization or other projects.
              </p>

              <div className="flex-split margin-top-3rem">
                <Checkbox
                  value={this.state.confirmProjectRemoval}
                  style="align-left"
                  description="I understand what happens when I remove this flow from this project."
                  onToggle={(e)=>{
                    this.setState({
                      confirmProjectRemoval: e
                    })
                  }}
                />
                <CustomButton 
                  display="Detach Flow from Project"
                  disabled={!canWrite || !canSubmit || !this.state.confirmProjectRemoval}
                  color="danger"
                  size="small"
                  thinking={projectReducer.tryingToDeleteInstalledComponent}
                  success={projectReducer.deleteInstalledComponentSuccess}
                  fail={projectReducer.deleteInstalledComponentFail}
                  onClick={()=>{
                    dispatch(tryToDeleteInstalledComponent({
                      id: project.id,
                      component_id: com.component_id,
                      installed: false
                    }))
                  }}
                  />
              </div>
            </div>
          </div>
        </div>
      }

      <div className="box box-danger">
        <div className="row">
          <div className="col-xl-12">
            <h4 className="no-margin">Deletion</h4>
            <hr className="hr-mini"/>
          </div>
          <div className="col-xl-3">
            <p className="thin-line-height">
              <small>
                Remove this flow from your organization completely.
              </small>
            </p>
          </div>
          <div className="col-xl-9">
            <p>
              This will remove all versions, including every instruction, function, variable and other customization. Deleting will immediately disable any active use of this flow via demo interface URLs and via API <span className="text-900">for all projects</span> within your organization.
            </p>
            {
                (component.projects && component.projects.length > 1 &&
                  <p className="text-danger text-900">
                    {
                      inProject ? 
                      <span>This flow is used by {component.projects.length - 1} other project{component.projects.length - 1 > 1 && "s"}.</span>
                      :
                      <span>This flow is used by {component.projects.length} project{component.projects.length !== 1 && "s"}.</span>
                    }
                  </p>
                )
              }
            <div className="flex-split margin-top-3rem">
              <Checkbox
                value={this.state.confirmDelete}
                style="align-left"
                description="I understand the consequences of deleting."
                onToggle={(e)=>{
                  this.setState({
                    confirmDelete: e
                  })
                }}
              />
              <CustomButton 
                display="Delete Completely"
                disabled={!canWrite || !canSubmit || !this.state.confirmDelete}
                color="danger"
                size="small"
                onClick={()=>{
                  this.setState({
                    deleteModal: true,
                    retypeNameDelete: ""
                  })
                }}
                />
            </div>
          </div>
        </div>            
      </div>

      <Modal
        show={this.state.deleteModal}
        exitable={true}
        onExit={e => this.setState({deleteModal: false})}
        cancelable={true}
        onCancel={e => this.setState({deleteModal: false})}
        acceptButtonLabel="Permenantly Delete"
        acceptButtonColor="danger"
        acceptButtonThinking={componentReducer.tryingToDeleteComponent}
        acceptButtonFail={componentReducer.deleteComponentFail}
        acceptButtonSuccess={componentReducer.deleteComponentSuccess}
        acceptButtonDisabled={this.state.retypeNameDelete !== component.display_name}
        acceptable={true}
        onAccept={e => {
          dispatch(tryToDeleteComponent({
            id: component.id,
            scope: component.scope
          }))
        }}
        content={<div>
          <h4 className="no-margin">Are you sure?</h4>
          <p>Deleting cannot be undone and may break things.</p>

          <CustomField
            label={<span>Type the name of this agent to confirm.</span>}
            value={this.state.retypeNameDelete}
            description={<span>The name is: <strong>{component.display_name}</strong></span>}
            onChange={e => {
              this.setState({
                retypeNameDelete: e.value
              })
            }}
            />
        </div>}
        /> 
    </div>
  }
}


const mapStateToProps = (state) => {
  const { userReducer, memberReducer, componentReducer, orgReducer, projectReducer } = state;

  return {
    userReducer, 
    memberReducer,
    componentReducer,
    orgReducer,
    projectReducer
  }
}

export default connect(mapStateToProps)(ComponentSettings);

  