import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import CustomField from 'kit/components/CustomField/CustomField'
import CustomSelect from 'kit/components/CustomSelect/CustomSelect'
import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown';

export const NodeCustomFormatter_request = ({ node, type, component, version }) => {

  let retval = structuredClone(type);

  if(!component || !version) return type;

  let loadedVersion = component.versions.find(v => v.id === version);

  if(!loadedVersion) return type;

  let variables = loadedVersion.variables || [];

  variables.forEach(variable => {
    retval.outputs.push({
      name: 'v_' + variable.key,
      display_name: variable.display_name || '${' + variable.key + '}',
      type: variable.type == "number" ? "number" : "string",
      description: variable.description || "No description provided.",
      additional: true,
      custom: true
    });
  });

  return retval;
}

// export const NodeSettings_request = ({ node, setNodeSettings, canWrite }) => {

//   if (!node.settings) {
//     return null;
//   }

//   const [settings, setSettings] = useState(node.settings || {});

//   return (
//     <div>
//       <CustomField
//         label="Raw JSON"
//         value={settings.raw}
//         rows={10}
//         canWrite={canWrite}
//         placeholder="Enter packet here..."
//         onChange={(e) => {
//           setSettings({
//             ...settings,
//             raw: e.value,
//           })
//         }}
//         onBlur={(e) => {
//           setNodeSettings(settings)
//         }}
//         />
//     </div>
//   );
// }


export const NodeRenderer_request = ({ node, type }) => {

  return (
    <div className="">
    </div>
  );
}