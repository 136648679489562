


export const toggleKindTools = data => ({
  type: 'TOGGLE_KIND_TOOLS',
  data
})

export const toggleSideTray = data => ({
  type: 'TOGGLE_SIDE_TRAY',
  data
})

export const setKindFilters = data => ({
  type: 'SET_KIND_FILTERS',
  data
})

export const setKindSort = data => ({
  type: 'SET_KIND_SORT',
  data
})

export const setDraggingGUIState = data => ({
  type: 'SET_DRAGGING_GUI_STATE',
  data
})

export const flowZoomToFit = data => ({
  type: 'FLOW_ZOOM_TO_FIT',
  data
})

export const setComponentScrollMode = data => ({
  type: 'SET_COMPONENT_SCROLL_MODE',
  data
})