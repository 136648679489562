import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import shortid from 'shortid';

import FlowNode from './FlowNode';
import FlowCursor from './FlowCursor';
import FlowLink from './FlowLink';

import {
  showTooltip,
  hideTooltip,
  setDraggingGUIState,
  undoBulkUpdateComponent,
  redoBulkUpdateComponent
} from 'actions/actions.export';


import {
  nodeWidth,
  nodeHeight,
  nodePaddingPerPort,
  calculateNodeHeight,
  filterNodesByDragBox,
  formatLinkId,
  unformatLinkId,
  calculateHandleYValue,
  calculatePluginDropZoneLocation,
  getHydratedType,
  getHydratedFlowNodeLibrary
} from './FlowBuilderUtils';

import './Flow.scss';
import CustomButton from 'kit/components/CustomButton/CustomButton';
import { diff } from 'deep-diff';
import { typeCompatible } from 'utilities/typeCompatible';

const FlowRenderer = React.memo(({ 
  component_id,
  nodes = [], 
  links = [], 
  cursors = [], 
  flowNodeLibrary,
  onCursorMove, 
  showMinimap = true, 
  onNodeChange, 
  onNewNode, 
  onSelectNodes, 
  onSelectLinks,
  onNewLink, 
  onOpenGenerator,
  onLinkChange, 
  onNodePlay,
  interactive = true,  
  highlightedNodeIds = [],
  selectedNodes = [],
  selectedLinks = [],
  component_version = 'draft'
}) => {

  const dispatch = useDispatch();
  const intelReducer = useSelector(state => state.intelReducer);
  const guiReducer = useSelector(state => state.guiReducer);
  const componentReducer = useSelector(state => state.componentReducer);

  const controlModeRef = useRef(guiReducer.componentScrollMode || 'mouse');

  const component = componentReducer.cache[component_id];
  const loadedVersion = component?.versions.find(v => v.id === component_version);
  
  const nodesRef = useRef(nodes);
  const [renderNodes, setRenderNodes] = useState(nodes);
  const linksRef = useRef(links);
  const [renderLinks, setRenderLinks] = useState(links);
  const selectedNodesRef = useRef(selectedNodes);
  const selectedLinksRef = useRef(selectedLinks);
  
  if(!flowNodeLibrary){ 
    flowNodeLibrary = getHydratedFlowNodeLibrary({
      defaultNodes: intelReducer.flow_nodes || [],
      component: componentReducer.cache[component_id],
      version: component_version
    });
  }
  
  const canvasWidth = 5000;
  const canvasHeight = 5000;
  const defaultZoom = { x: 0, y: 0, k: 1 };

  const zoomRange = [0.1, 5];

  const [zoom, setZoom] = useState(defaultZoom);
  const zoomRef = useRef(zoom);
  const svgRef = useRef(null);
  const isPanning = useRef(false);
  const focusRef = useRef(false);
  const lastMousePosition = useRef({ x: 0, y: 0, rawX: 0, rawY: 0, svgX: 0, svgY: 0 });
  const containerDimensions = useRef({ width: 0, height: 0 });
  const [isZoomKeyActive, setIsZoomKeyActive] = useState(false);
  const [isPanKeyActive, setIsPanKeyActive] = useState(false);
  const dragBounds = useRef({dragging: false});
  const [dragBoundsToDraw, setDragBoundsToDraw] = useState({dragging: false});
  
  const interactingWithNode = useRef(null);
  const [isDraggingPlugin, setIsDraggingPlugin] = useState(false);
  
  const draggingNode = useRef(null);
  const localDraggingGUIStateRef = useRef(guiReducer.draggingGUIState);

  const [snapLines, setSnapLines] = useState([]);

  const draftLinkRef = useRef(null);
  const [draftLink, setDraftLink] = useState(null);

  const potentialDropZoneRef = useRef(null);

  const lastZoomToFit = useRef(false);
  const zoomToFitInterval = useRef(null);
  const [zoomReady, setZoomReady] = useState(false);
  const [initialLoadTime, setInitialLoadTime] = useState(new Date().getTime());

  useEffect(() => {
    draftLinkRef.current = draftLink;
  }, [draftLink]);

  useEffect(() => {
    selectedNodesRef.current = selectedNodes;
  }, [selectedNodes]);

  useEffect(() => {
    selectedLinksRef.current = selectedLinks;
  }, [selectedLinks]);

  useEffect(() => {
    controlModeRef.current = guiReducer.componentScrollMode || 'mouse';
  }, [guiReducer.componentScrollMode]);

  useEffect(() => {

    // loop through nodes and if any don't have an x or y, set them to the center of the current zoom view
    let newNodes = nodes.map(node => {
      if(node.x === undefined || node.y === undefined){

        // if we don't have a height, calculate it
        if(!node.height){
          node.height = calculateNodeHeight(node, flowNodeLibrary);
        }

        if(!node.width){
          node.width = nodeWidth;
        }

        let newNode = {
          ...node,
          x: zoom.x - node.width / 2,
          y: zoom.y - node.height / 2
        }

        // pass the updated node to the onNodeChange function
        if(onNodeChange) onNodeChange({
          node_id: newNode.id,
          changes: {
            x: newNode.x,
            y: newNode.y
          }
        });

        return newNode;
      }
      return node;
    });


    nodesRef.current = newNodes;
    setRenderNodes(newNodes);
  }, [nodes]);

  useEffect(() => {
    linksRef.current = links;
    setRenderLinks(links);
  }, [links]);

  useEffect(() => {
    zoomRef.current = zoom;
  }, [zoom]);

  useEffect(() => {
    clearInterval(zoomToFitInterval.current);
    zoomToFitInterval.current = setInterval(zoomToFit, 100);
    
    setInitialLoadTime(new Date().getTime());

  }, []);

  useEffect(() => {
    localDraggingGUIStateRef.current = guiReducer.draggingGUIState ? true : false;
  }, [guiReducer.draggingGUIState]);

  useEffect(() => {
    
    if(guiReducer.flowZoomToFit !== lastZoomToFit.current){
      lastZoomToFit.current = guiReducer.flowZoomToFit;

      if(guiReducer.flowZoomToFit){
        zoomToFit();
      }
    }
  }, [guiReducer.flowZoomToFit]);

  const zoomToFit = () => {
    if(!svgRef.current) return;
    if(nodesRef.current.length === 0) return;
    if(!containerDimensions.current.width) return;

    if(new Date().getTime() - initialLoadTime < 10000 || nodesRef.current.length > 0){
      clearInterval(zoomToFitInterval.current);  
    }

    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    nodesRef.current.forEach(node => {  
      if(node.x < minX) minX = node.x;
      if(node.y < minY) minY = node.y;
      if(node.x + nodeWidth > maxX) maxX = node.x + nodeWidth;
      if(node.y + (calculateNodeHeight(node, flowNodeLibrary) || 0) > maxY) maxY = node.y + calculateNodeHeight(node, flowNodeLibrary || []);
    })
    
    let padding = 200;

    minX -= padding;
    minY -= padding;
    maxX += padding;
    maxY += padding;

    let width = maxX - minX;
    let height = maxY - minY;

    let newZoom = {
      x: minX + width / 2,
      y: minY + height / 2,
      k: Math.min(containerDimensions.current.width / width, containerDimensions.current.height / height)
    }

    setZoom(newZoom);
    zoomRef.current = newZoom;
    setZoomReady(true);
  }

  const panEventHandler = (e) => {
    
      // Handle panning
      const dx = e.deltaX;
      const dy = e.deltaY;

      setZoom((prevZoom) => ({
        ...prevZoom,
        x: prevZoom.x + dx / prevZoom.k,
        y: prevZoom.y + dy / prevZoom.k,
      }));
  }

  const zoomEventHandler = (e) => {

    // Get x and y relative to the top left of the container
    let clientBoundingRect = svgRef.current.getBoundingClientRect();
    let screenX = e.clientX - clientBoundingRect.left - clientBoundingRect.width / 2;
    let screenY = e.clientY - clientBoundingRect.top - clientBoundingRect.height / 2;
  
    setZoom((prevZoom) => {
      let { deltaY } = e;

      // decrease the impact of deltaY

      const scale = Math.max(zoomRange[0], Math.min(zoomRange[1], prevZoom.k * (deltaY > 0 ? 0.97 : 1.03)));

      let tempNewZoom = {
        x: prevZoom.x,
        y: prevZoom.y,
        k: scale
      }
      
      // convert screenX and screenY to SVG coordinates using the previous zoom level
      const translated = screenToSvgCoord(screenX, screenY);
      
      // find out how far those coordinates are now with the new scale
      const newScreenCoords = svgToScreenCoord(translated.x, translated.y, tempNewZoom);
      
      // find the difference between the new screen coordinates and the old screen coordinates
      const dx = newScreenCoords.x - screenX;
      const dy = newScreenCoords.y - screenY;

      // adjust the zoom x and y by the difference, scaled by the new scale
      tempNewZoom.x += dx / tempNewZoom.k;
      tempNewZoom.y += dy / tempNewZoom.k;

      return tempNewZoom;
    });
  }

  const handleWheel = (e) => {
    e.preventDefault();

    switch(controlModeRef.current){
      case 'trackpad':

        // check to see if the alt key is being held down
        const isZooming = e.altKey || e.metaKey || e.ctrlKey;

        if (isZooming) {
          zoomEventHandler(e);
        } else {
          panEventHandler(e);
        }
        break;

      case 'mouse':
        zoomEventHandler(e);
        break;
    }
  };
  
  
  
  const handleMouseDown = (e) => {
    if(!interactingWithNode.current){
      if(isPanning.current === 'armed'){
        isPanning.current = true;
      } else {
        dragBounds.current = {
          dragging: true
        }
      }
    } else {
      // if we are interacting with a node, check if its a plugin 
      let node = nodesRef.current.find(n => n.id === interactingWithNode.current);
      let nodeType = flowNodeLibrary.find(fn => fn.name === node.type);
      console.log('nodeType', nodeType);
      if(nodeType && nodeType.is_plugin){
        setIsDraggingPlugin(true);
      } else {
        setIsDraggingPlugin(false);
      }
    }
  };

  const handleMouseMove = (e) => {

    // get x and y relative to the top left of the container
    let clientBoundingRect = svgRef.current.getBoundingClientRect();

    let screenX = e.clientX - clientBoundingRect.left - clientBoundingRect.width / 2;
    let screenY = e.clientY - clientBoundingRect.top - clientBoundingRect.height / 2;

    const dx = e.clientX - lastMousePosition.current.rawX;
    const dy = e.clientY - lastMousePosition.current.rawY;

    // where is the mouse currently in the coordinate system?
    let translated = screenToSvgCoord(screenX, screenY);
    
    // MOVING A NODE
    if(draggingNode.current){

      if(!localDraggingGUIStateRef.current){
        // dispatch(setDraggingGUIState(true));
      }

      // find the current node
      let node = nodesRef.current.find(n => n.id === draggingNode.current.id);
      
      // if we are dragging just a single node, is it a plugin?
      // if(selectedNodesRef.current.length <= 1){
        let nodeType = flowNodeLibrary.find(fn => fn.name === node.type);
        if(nodeType && nodeType.is_plugin){
          
          setIsDraggingPlugin(true);

          // are we within the dropzone of a node that accepts plugins?
          let acceptingNodes = nodesRef.current.filter(n => {
            if(n.id === draggingNode.current.id) return false;
            let nodeType = flowNodeLibrary.find(fn => fn.name === n.type);
            if(nodeType && nodeType.accepts_plugins){
              let dropZoneDimensions = calculatePluginDropZoneLocation(n, flowNodeLibrary, nodesRef.current, linksRef.current);

              if(translated.x > dropZoneDimensions.x && translated.x < dropZoneDimensions.x + dropZoneDimensions.width && translated.y > dropZoneDimensions.y && translated.y < dropZoneDimensions.y + dropZoneDimensions.height){
                return true;
              }
            }
            return false;
          });

          if(acceptingNodes.length > 0){
            potentialDropZoneRef.current = acceptingNodes[0].id;
          } else {
            potentialDropZoneRef.current = null;
          }


        } else {
          setIsDraggingPlugin(false);
        }
      // }

      let newX = translated.x - draggingNode.current.relX;
      let newY = translated.y - draggingNode.current.relY;

      // lets see if this is nearly in line with any other nodes and snap to it
      let snapDistance = 15;

      nodesRef.current.forEach(n => {
        if(n.id === draggingNode.current.id) return;

        if(!n.height){
          n.height = calculateNodeHeight(n, flowNodeLibrary) || 50;
        }

        n.width = nodeWidth;

        n.cx = n.x + nodeWidth / 2;
        n.cy = n.y + n.height / 2;
      });

      let tempNode = {
        x: newX,
        y: newY,
        width: nodeWidth,
        height: calculateNodeHeight(node, flowNodeLibrary) || 50
      }

      tempNode.cx = tempNode.x + nodeWidth / 2;
      tempNode.cy = tempNode.y + tempNode.height / 2;

      let newSnapLines = [];

      nodesRef.current.forEach(n => {
        if(n.id === draggingNode.current.id) return;

        // if its a selected node, don't snap to it
        if(selectedNodesRef.current.includes(n.id)) return;

        // check if this node is within 300 pixels of the current node using euclidean distance
        let distance = Math.sqrt(Math.pow(n.cx - tempNode.cx, 2) + Math.pow(n.cy - tempNode.cy, 2));

        // scale needed snapDistance by distance, so that the closer the node is, the bigger the snap distance is
        let scaledSnapDistance = snapDistance - (distance / 2000) * snapDistance;
          
        // check if this node is within 15 pixels of the current node on the centered x axis
        if(Math.abs(n.cx - tempNode.cx) < scaledSnapDistance){
          newX = n.cx - nodeWidth / 2;

          newSnapLines.push({
            x1: n.cx,
            y1: n.cy,
            x2: newX + nodeWidth / 2,
            y2: tempNode.cy
          });
        } else if(Math.abs(n.x - tempNode.x) < scaledSnapDistance){

          // check if this node is within 15 pixels of the current node on the left x axis
          newX = n.x;

          newSnapLines.push({
            x1: n.x + nodeWidth,
            y1: n.y,
            x2: newX,
            y2: tempNode.y
          });
        }

        // check if this node is within 15 pixels of the current node on the centered y axis
        if(Math.abs(n.cy - tempNode.cy) < scaledSnapDistance){
          newY = n.cy - tempNode.height / 2;

          newSnapLines.push({
            x1: n.cx,
            y1: n.cy,
            x2: tempNode.cx,
            y2: newY + tempNode.height / 2
          });
        } else if(Math.abs(n.y - tempNode.y) < scaledSnapDistance){

          // check if this node is within 15 pixels of the current node on the top y axis
          newY = n.y;

          newSnapLines.push({
            x1: n.x + nodeWidth / 2,
            y1: n.y,
            x2: newX + nodeWidth / 2,
            y2: newY
          });
        }
      });

      let originalX = node.x;
      let originalY = node.y;
    
      if(onNodeChange) onNodeChange({
        node_id: draggingNode.current.id,
        changes: {
          x: (newX),
          y: (newY)
        }
      });
      

      // for all other nodes that are selected, but aren't the current node, move them by the same amount
      nodesRef.current.forEach(n => {
        if(n.id === draggingNode.current.id) return;
        
        if(selectedNodesRef.current.includes(n.id)){
          if(onNodeChange) onNodeChange({
            node_id: n.id,
            changes: {
              x: n.x + (newX - originalX),
              y: n.y + (newY - originalY)
            }
          });
        }
      });


      // set the node change here too
      nodesRef.current = nodesRef.current.map(n => {
        if(n.id === draggingNode.current.id){
          return {
            ...n,
            x: (newX),
            y: (newY)
          }
        } else if(selectedNodesRef.current.includes(n.id)){
          return {
            ...n,
            x: (n.x + (newX - originalX)),
            y: (n.y + (newY - originalY))
          }
        }
        return n;
      });


      setRenderNodes(nodesRef.current);
      setSnapLines(newSnapLines);

    } 

    // MOVING THE CANVAS
    if (isPanning.current === true && !interactingWithNode.current) {
      setZoom((prevZoom) => {

        let newx = prevZoom.x - dx / prevZoom.k;
        let newy = prevZoom.y - dy / prevZoom.k;

        let possibleViewBoxX = newx - screenToSvg(containerDimensions.current.width) / 2;
        let possibleViewBoxY = newy - screenToSvg(containerDimensions.current.height) / 2;
        let possibleViewBoxWidth = screenToSvg(containerDimensions.current.width);
        let possibleViewBoxHeight = screenToSvg(containerDimensions.current.height);

        if(possibleViewBoxX < -canvasWidth){
          newx = -canvasWidth + screenToSvg(containerDimensions.current.width) / 2;
        }

        if(possibleViewBoxY < -canvasHeight){
          newy = -canvasHeight + screenToSvg(containerDimensions.current.height) / 2;
        }

        if(possibleViewBoxX + possibleViewBoxWidth > canvasWidth){
          newx = canvasWidth - screenToSvg(containerDimensions.current.width) / 2;
        }

        if(possibleViewBoxY + possibleViewBoxHeight > canvasHeight){
          newy = canvasHeight - screenToSvg(containerDimensions.current.height) / 2;
        }

        return {
          ...prevZoom,
          x: newx,
          y: newy
        }
      })
    }

    // MOVING A DRAG BOX
    if(isPanning.current === false && !interactingWithNode.current && dragBounds.current && dragBounds.current.dragging){
      
      if(!dragBounds.current.x){
        dragBounds.current = {
          dragging: true,
          x: translated.x,
          y: translated.y,
          width: 0,
          height: 0
        }
      } else {
        let newWidth = translated.x - dragBounds.current.x;
        let newHeight = translated.y - dragBounds.current.y;

        dragBounds.current.width = newWidth;
        dragBounds.current.height = newHeight;
      }
      
      setDragBoundsToDraw({
        dragging: true,
        x: dragBounds.current.x,
        y: dragBounds.current.y,
        width: dragBounds.current.width,
        height: dragBounds.current.height
      });
    } 

    // DRAWING A LINK
    let inputs = [];
    let outputs = [];
    if(draftLinkRef.current && draftLinkRef.current.drafting){
      // figure out if we need placeholder source or target
      if(draftLinkRef.current.sourceNode){
        draftLinkRef.current.placeholderTargetX = translated.x;
        draftLinkRef.current.placeholderTargetY = translated.y;

        // find nearest input on target node that is accepting this output type
        let sourceNodeType = flowNodeLibrary.find(fn => fn.name === draftLinkRef.current.sourceNode.type);
        let fromType = sourceNodeType.outputs.find(o => o.name === draftLinkRef.current.sourceOutput).type;
        
        // filter the list of all nodes to only those that have an input that accepts this type
        let nodesWithInput = nodesRef.current.filter(n => {
          let nodeType = flowNodeLibrary.find(fn => fn.name === n.type);
          if(n.id === draftLinkRef.current.sourceNode.id) return false;
          if(!nodeType) return false;
          let matchingInputs = nodeType.inputs.filter(i => {
            let t = typeCompatible(i.type,fromType);
            if(t === 'compatible') return true;
            if(t === 'maybe') return true;
            return false;
          });

          // if this node isnt show_additional, then remove any additional:true inputs from the matching list
          if(!n.show_additional){
            matchingInputs = matchingInputs.filter(i => !i.additional);
          }

          matchingInputs.forEach(i => {

            let x = n.x;
            let y = calculateHandleYValue(n, flowNodeLibrary, i.name, 'inputs') + n.y;

            inputs.push({
              node_id: n.id,
              input: i,
              x: x,
              y: y,
              distance: Math.sqrt(Math.pow(x - translated.x, 2) + Math.pow(y - translated.y, 2))
            });
          });
        });

        // filter inputs by distance < 250
        // inputs = inputs.filter(i => i.distance < 250);
        // console.log('inputs', inputs);

        // sort the inputs by distance from the current translated x and y
        
        if(inputs.length > 0){
          inputs.sort((a, b) => {
            return a.distance - b.distance;
          });
          
          if(inputs[0].distance < 250){
            draftLinkRef.current.temporaryTarget = {
              node: nodesRef.current.find(n => n.id === inputs[0].node_id),
              input: inputs[0].input.name
            }
          }
        } else {
          draftLinkRef.current.temporaryTarget = null;
        }

        
      } else {
        draftLinkRef.current.placeholderSourceX = translated.x;
        draftLinkRef.current.placeholderSourceY = translated.y;
      }

      // console.log('draftLinkRef.current', draftLinkRef.current);
      setDraftLink({
        ...draftLinkRef.current,
        acceptableInputs: inputs,
        acceptableOutputs: outputs
      });
    }

    lastMousePosition.current = {
      rawX: e.clientX,
      rawY: e.clientY,
      x: screenX,
      y: screenY,
      svgX: translated.x,
      svgY: translated.y
    }

    if(onCursorMove){
      onCursorMove((translated.x), (translated.y));
    }
  };

  const handleMouseUp = () => {
    if(isPanning.current === true){
      isPanning.current = 'armed';
    }

    if(draftLinkRef.current && draftLinkRef.current.drafting){
      // delete the draft link
      setTimeout(() => {
        setDraftLink(null);
      }, 100);
    }
    
    setSnapLines([]);
    

    if(potentialDropZoneRef.current && draggingNode.current){
      // then we have a plugin that we are dragging and we are over a drop zone, we need to automate some links between this plugin's outputs and the drop zone's inputs
      let pluginNode = nodesRef.current.find(n => n.id === draggingNode.current.id);
      let pluginNodeType = flowNodeLibrary.find(fn => fn.name === pluginNode.type);
      
      let dropZoneNode = nodesRef.current.find(n => n.id === potentialDropZoneRef.current);
      let dropZoneNodeType = flowNodeLibrary.find(fn => fn.name === dropZoneNode.type);

      let newLink = {
        from: {
          node_id: pluginNode.id,
          output: 'plugin_config'
        },
        to: {
          node_id: dropZoneNode.id,
          input: 'plugins'
        }
      }

      if(onNewLink) onNewLink(newLink);
    }

    setIsDraggingPlugin(false);
    potentialDropZoneRef.current = null;

    // if we were drawing a drag bounds, lets stop that
    if(dragBounds.current && dragBounds.current.dragging){

      // is the width or height actually set and non-zero?
      if(dragBounds.current.width || dragBounds.current.height){

        let nodesToFilter = JSON.parse(JSON.stringify(nodesRef.current));

        nodesToFilter.forEach(n => {
          if(!n.height){
            n.height = calculateNodeHeight(n, flowNodeLibrary) || 50;
          }
          n.width = nodeWidth;
        });
        
        let newSelectedNodes = filterNodesByDragBox({
          dragX: dragBounds.current.x,
          dragY: dragBounds.current.y,
          dragWidth: dragBounds.current.width,
          dragHeight: dragBounds.current.height,
          objects: nodesToFilter
        }).map(n => n.id);

        if(onSelectNodes) onSelectNodes(newSelectedNodes);

        // setSelectedNodes(newSelectedNodes);

      } else {
        if(!interactingWithNode.current){
          if(onSelectNodes) onSelectNodes([]);
          // setSelectedNodes([]);
        }

        if(onSelectLinks) onSelectLinks([]);
        // setSelectedLinks([]);
      }
    }

    interactingWithNode.current = undefined;
    draggingNode.current = undefined;

    dragBounds.current = {
      dragging: false
    }

    setDragBoundsToDraw(dragBounds.current);

    // are we drawing a link?
    if(draftLinkRef.current && draftLinkRef.current.drafting){
      if(draftLinkRef.current.temporaryTarget){
        if(onNewLink) onNewLink({
          from: {
            node_id: draftLinkRef.current.sourceNode.id,
            output: draftLinkRef.current.sourceOutput
          },
          to: {
            node_id: draftLinkRef.current.temporaryTarget.node.id,
            input: draftLinkRef.current.temporaryTarget.input
          }
        });
      }

      setDraftLink(null);
    }
  };

  const handleKeyDown = (e) => {
    if (e.altKey || e.metaKey || e.ctrlKey) {
      setIsZoomKeyActive(true);
    }

    // is space key?
    if(e.keyCode === 32 && isPanning.current === false){
      setIsPanKeyActive(true);
      isPanning.current = 'armed';
      return;
    }

    // is delete key or backspace key or d key?
    if((e.keyCode === 8 || e.keyCode === 46 || e.keyCode === 68)){
      
      // do we have any links selected
      if(selectedLinksRef.current.length > 0){
        // for each link
        selectedLinksRef.current.forEach(linkId => {
          if(onLinkChange) onLinkChange({
            link_id: linkId,
            operation: 'delete'
          });
        });
      }
      return;
    }

    // did we copy?
    if(focusRef.current){
      if(e.key === 'c' && (e.metaKey || e.ctrlKey)){
        
        const unsecuredCopyToClipboard = (text) => { const textArea = document.createElement("textarea"); textArea.value=text; document.body.appendChild(textArea); textArea.focus();textArea.select(); try{document.execCommand('copy')}catch(err){console.error('Unable to copy to clipboard',err)}document.body.removeChild(textArea)};

        // put the currently selected nodes in full detail on the clipboard
        let nodes = selectedNodesRef.current.map(nodeId => {
          return nodesRef.current.find(n => n.id === nodeId);
        })

        // grab any links that contain a to and from node that are both in the selected nodes
        let links = linksRef.current.filter(link => {
          return selectedNodesRef.current.includes(link.from.node_id) && selectedNodesRef.current.includes(link.to.node_id);
        });

        let content = JSON.stringify({
          copied_nodes: nodes,
          copied_links: links
        }, null, 2);

        if (window.isSecureContext && navigator.clipboard) {
          navigator.clipboard.writeText(content);
        } else {
          unsecuredCopyToClipboard(content);
        }

        return;
      }

      // if we pasted
      if(e.key === 'v' && (e.metaKey || e.ctrlKey)){
        
        let content;
        if (window.isSecureContext && navigator.clipboard) {
          navigator.clipboard.readText().then(clipText => {
            content = JSON.parse(clipText);
          });
        }

        if(content){
          // check for content.copied_nodes and content.copied_links
          if(content.copied_nodes){

            // find the overall center of the copied nodes
            let centerX = 0;
            let centerY = 0;
            content.copied_nodes.forEach(node => {
              centerX += node.x;
              centerY += node.y;
            });

            centerX /= content.copied_nodes.length;
            centerY /= content.copied_nodes.length;

            // find the center of the current view
            let viewCenterX = zoomRef.current.x;
            let viewCenterY = zoomRef.current.y;

            // add each node to the current nodes
            content.copied_nodes.forEach(node => {
              
              // move the node to the center of the view, relative to the center of the copied nodes 
              node.x = (node.x - centerX) + viewCenterX;
              node.y = (node.y - centerY) + viewCenterY;
              
              // check through all the other nodes to make sure its not directly on top of one
              let i = 0;
              while(nodesRef.current.find(n => n.x === node.x && n.y === node.y)){
                node.x += 50;
                node.y += 50;
                i++;
                if(i > 100){
                  break;
                }
              }
            

              // give every node a new ID
              let oldId = node.id;
              node.id = shortid.generate();

              // find all the links that point to the old ID and update them to point to the new ID
              content.copied_links.forEach(link => {
                if(link.from.node_id === oldId){
                  link.from.node_id = node.id;
                }
                if(link.to.node_id === oldId){
                  link.to.node_id = node.id;
                }
              });

              if(onNewNode) onNewNode(node);
            });
            

            // set the new nodes to be selected
            if(onSelectNodes) onSelectNodes(content.copied_nodes.map(n => n.id));
          }
          if(content.copied_links){
            // add each link to the current links
            content.copied_links.forEach(link => {

              // double check that the nodes this link is pointing to actually exist
              let fromNode = content.copied_nodes.find(n => n.id === link.from.node_id);
              let toNode = content.copied_nodes.find(n => n.id === link.to.node_id);

              if(onNewLink) onNewLink(link);
            });
          }
        }
        
        return;
      }

      // if we hit undo
      if(e.key === 'z' && (e.metaKey || e.ctrlKey) && !e.shiftKey){
        dispatch(undoBulkUpdateComponent({id: component_id}))
        return;
      }

      // if we hit redo (shift z)
      if(e.key === 'z' && (e.metaKey || e.ctrlKey) && e.shiftKey){
        dispatch(redoBulkUpdateComponent({id: component_id}))
        return;
      }
    }

  };

  const handleKeyUp = (e) => {
    setIsZoomKeyActive(false);
    setIsPanKeyActive(false);
    isPanning.current = false;
  };

  const handleLinkStart = useCallback((e, node_id, portName, portType) => {

    e.preventDefault();

    let clientBoundingRect = svgRef.current.getBoundingClientRect();
    let screenX = e.clientX - clientBoundingRect.left - clientBoundingRect.width / 2;
    let screenY = e.clientY - clientBoundingRect.top - clientBoundingRect.height / 2;
    let translated = screenToSvgCoord(screenX, screenY);

    let node = nodesRef.current.find(n => n.id === node_id);

    if(!node) return;
    
    if(portType === 'outputs'){

      setDraftLink({
        startedBy: 'outputs',
        drafting: true,
        sourceNode: node,
        sourceOutput: portName,

        placeholderTargetX: translated.x,
        placeholderTargetY: translated.y
      });
      
    } else if(portType === 'inputs'){

      setDraftLink({
        startedBy: 'inputs',
        drafting: true,
        placeholderSourceX: translated.x,
        placeholderSourceY: translated.y,

        targetNode: node,
        targetInput: portName,
      });
    }

  }, []);

  const handleLinkEnd = useCallback((e, node_id, portName, portType) => {
    e.preventDefault();

    // were we drawing a link?
    if(draftLinkRef.current && draftLinkRef.current.drafting){

      // are we drawing from an output to an input?
      if(draftLinkRef.current.startedBy !== portType){

        // find this node
        let node = nodesRef.current.find(n => n.id === node_id);

        
        if(node){
          

          let finalDraftedLink;
          if(portType === 'inputs'){
            // make sure this node ID isn't the same as the source node ID
            if(draftLinkRef.current.sourceNode.id !== node.id){

              finalDraftedLink = {
                from: {
                  node_id: draftLinkRef.current.sourceNode.id,
                  output: draftLinkRef.current.sourceOutput
                },
                to: {
                  node_id: node.id,
                  input: portName
                }
              }
            }
          } else {
            // make sure this node ID isn't the same as the target node ID
            if(draftLinkRef.current.targetNode.id !== node.id){
              finalDraftedLink = {
                from: {
                  node_id: node.id,
                  output: portName
                },
                to: {
                  node_id: draftLinkRef.current.targetNode.id,
                  input: draftLinkRef.current.targetInput
                }
              }
            }
          }

          if(onNewLink){
            onNewLink(finalDraftedLink);
          }

          // also add this link to the local state
          setRenderLinks(prevRenderLinks => {
            return [
              ...prevRenderLinks,
              finalDraftedLink
            ]
          });
        }
      }
    } 

    setDraftLink(null);
  }, []);


  // a function to figure out the dimensions of the SVG element in pixels on the user's screen
  const updateDimensions = () => {
    if(!svgRef.current) return;
    const { width, height } = svgRef.current.getBoundingClientRect();
    containerDimensions.current = { width, height };
  };

  // a function to translate any length from the user's screen to the SVG coordinate system
  const screenToSvg = (value, optionalZoom) => {
    if(optionalZoom){
      return value / optionalZoom.k;
    }
    return value / zoomRef.current.k;
  }

  // a function to translate any svg length to the user's screen
  const svgToScreen = (value, optionalZoom) => {
    if(optionalZoom){
      return value * optionalZoom.k;
    }
    return value * zoomRef.current.k;
  }

  // a function to translate any coordinate from the user's screen to the SVG coordinate system
  const screenToSvgCoord = (x, y, optionalZoom) => {
    let zoomToUse = optionalZoom || zoomRef.current;

    return {
      x: screenToSvg(x, zoomToUse) + (zoomToUse.x),
      y: screenToSvg(y, zoomToUse) + (zoomToUse.y),
    };
  }

  // a function to translate any coordinate from the SVG coordinate system to the user's screen
  const svgToScreenCoord = (x, y, optionalZoom) => {
    
    let zoomToUse = optionalZoom || zoomRef.current;

    return {
      x: svgToScreen(x - zoomToUse.x, zoomToUse),
      y: svgToScreen(y - zoomToUse.y, zoomToUse)
    };
  }


  // useEffect(() => {
  //   selectedNodes = selectedNodes;
  // }, [selectedNodes]);

  // useEffect(() => {
  //   selectedNodes = selectedNodesFromParent;
  //   setSelectedNodes(selectedNodesFromParent);
  // }, [selectedNodesFromParent]);

  useEffect(() => {
    selectedLinks = selectedLinks;
  }, [selectedLinks]);


  useEffect(() => {
    const svg = svgRef.current;

    if(interactive){
      svg.addEventListener('wheel', handleWheel);
      svg.addEventListener('mousedown', handleMouseDown);
      svg.addEventListener('mousemove', handleMouseMove);
      svg.addEventListener('mouseup', handleMouseUp);
      svg.addEventListener('mouseleave', handleMouseUp);
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);
    }

    updateDimensions();

    const resizeObserver = new ResizeObserver(updateDimensions);
    resizeObserver.observe(svgRef.current);

    return () => {
      if(interactive){
        svg.removeEventListener('wheel', handleWheel);
        svg.removeEventListener('mousedown', handleMouseDown);
        svg.removeEventListener('mousemove', handleMouseMove);
        svg.removeEventListener('mouseup', handleMouseUp);
        svg.removeEventListener('mouseleave', handleMouseUp);
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      }

      resizeObserver.disconnect();

    };
  }, []);

  // Calculate the view box based on the zoom and pan and the user's screen dimensions, where zoom x and y are the center position of the view box
  let viewBoxX = zoomRef.current.x - screenToSvg(containerDimensions.current.width) / 2;
  let viewBoxY = zoomRef.current.y - screenToSvg(containerDimensions.current.height) / 2;

  // Make sure the view box doesn't go outside the canvas
  const maxViewBoxX = canvasWidth - screenToSvg(containerDimensions.current.width);
  const maxViewBoxY = canvasHeight - screenToSvg(containerDimensions.current.height);

  viewBoxX = Math.max(-canvasWidth, Math.min(maxViewBoxX, viewBoxX));
  viewBoxY = Math.max(-canvasHeight, Math.min(maxViewBoxY, viewBoxY));

  const viewBoxWidth = screenToSvg(containerDimensions.current.width);
  const viewBoxHeight = screenToSvg(containerDimensions.current.height);

  // create a scaled version of the view box for the minimap, converting 5000x5000 to 50x50
  const minimapViewBoxX = viewBoxX / 100;
  const minimapViewBoxY = viewBoxY / 100;
  const minimapViewBoxWidth = viewBoxWidth / 100;
  const minimapViewBoxHeight = viewBoxHeight / 100;

  let adjustedDragBounds;
  
  if(dragBoundsToDraw.dragging){
    // create an adjusted drag bounds so that we are always drwaing from the top left to the bottom right
    adjustedDragBounds = {
      x: dragBoundsToDraw.x,
      y: dragBoundsToDraw.y,
      width: dragBoundsToDraw.width,
      height: dragBoundsToDraw.height
    }

    if(adjustedDragBounds.width < 0){
      adjustedDragBounds.x += adjustedDragBounds.width;
      adjustedDragBounds.width = Math.abs(adjustedDragBounds.width);
    }

    if(adjustedDragBounds.height < 0){
      adjustedDragBounds.y += adjustedDragBounds.height;
      adjustedDragBounds.height = Math.abs(adjustedDragBounds.height);
    }
  }


  const handleLinkSelect = useCallback((id) => {
    if(selectedLinks.includes(id)){
      if(onSelectLinks) onSelectLinks([]);
    } else {
      if(onSelectLinks) onSelectLinks([id]);
    }
  }, [selectedLinks]); 

  const handleLinkDelete = useCallback((id) => {
    if(onLinkChange) onLinkChange({
      link_id: id,
      operation: 'delete'
    });
  }, []);


  const handleNodeSelect = useCallback((id) => {  
    // setSelectedNodes([id]);
    if(onSelectNodes) onSelectNodes([id]);
  }, []);

  const handleNodeMouseDown = useCallback((e, id) => {
    interactingWithNode.current = id;
    
    // if its a plugin, then set the dragging plugin state
    let node = nodesRef.current.find(n => n.id === id);
    let nodeType = flowNodeLibrary.find(fn => fn.name === node.type);
    if(nodeType && nodeType.is_plugin){
      setIsDraggingPlugin(true);
    } else {  
      setIsDraggingPlugin(false);
    }
    dispatch(hideTooltip());
  }, []);

  const handleNodeMouseUp = useCallback(e => {
    e.stopPropagation();
    interactingWithNode.current = undefined;
  }, []);

  const handleNodeDragStart = useCallback((e, id) => {
    interactingWithNode.current = id;

    // where is the mouse relative to the node
    const x = e.clientX - e.target.getBoundingClientRect().x;
    const y = e.clientY - e.target.getBoundingClientRect().y;

    // scale the x and y to the svg coordinate system
    const svgX = screenToSvg(x);
    const svgY = screenToSvg(y);

    draggingNode.current = {
      id,
      relX: svgX,
      relY: svgY
    }
  }, []);

  const handleNodeDragEnd = useCallback((e, id) => {
    interactingWithNode.current = undefined;
    draggingNode.current = undefined;
  }, []);

  const handleNodeChange = useCallback(({node_id, changes, instant}) => {
    if(onNodeChange) onNodeChange({node_id, changes, instant})

    // also update the local state
    setRenderNodes(prevRenderNodes => {
      return prevRenderNodes.map(n => {
        if(n.id === node_id){
          
          return {
            ...n,
            ...changes
          }
        }
        return n;
      })
    });
  }, []);

  const handleNodePlay = useCallback((node_id) => {
    if(onNodePlay) onNodePlay(node_id);
  }, []);

  const handleNodeShowAdditional = useCallback((node_id, show_additional) => {
    
    onNodeChange({
      node_id,
      changes: {
        show_additional: show_additional
      },
      instant: true
    });
  }, []);

  const handleFocus = () => {
    focusRef.current = true;
  }

  const handleBlur = () => {
    focusRef.current = false;
  }


  
  return (
    <div className={"flow-renderer-parent " + (interactive === false ? "no-pointer-events no-select" : "")}>
      <svg
        ref={svgRef}
        className={`flow-renderer ${isZoomKeyActive ? 'zoom-cursor' : ''} ${isPanKeyActive ? 'pan-cursor' : ''}`}
        tabIndex={-1}
        onFocus={handleFocus}
        onBlur={handleBlur}
        width="100%"
        height="100%"
        viewBox={`${viewBoxX} ${viewBoxY} ${viewBoxWidth} ${viewBoxHeight}`}
      >
        <defs>
          <pattern id="dotGrid" width="20" height="20" patternUnits="userSpaceOnUse">
            <circle cx="10" cy="10" r="1" fill="black" opacity="0.25" />
          </pattern>
          <linearGradient id="flow-node-gradient" x1="0%" y1="50%" x2="100%" y2="50%">
            <stop offset="0%" stopColor="#1FB8FF">
              <animate attributeName="stop-color" values="#1FB8FF; rgba(255,255,255,0); #1FB8FF" dur="1s" repeatCount="indefinite"></animate>
            </stop>
            <stop offset="100%" stopColor="rgba(255,255,255,0)">
              <animate attributeName="stop-color" values="rgba(255,255,255,0); #1FB8FF; rgba(255,255,255,0)" dur="1s" repeatCount="indefinite"></animate>
            </stop>
          </linearGradient>

        </defs>

        {
          interactive && 
          
          <rect 
            onDoubleClick={e => {
              if(onOpenGenerator) onOpenGenerator(e);
            }}
            x={-canvasWidth} 
            y={-canvasHeight} 
            width={canvasWidth * 2} 
            height={canvasHeight * 2} 
            fill="url(#dotGrid)" 
            className="flow-renderer-bg-fill"
            />
        }

        {/* draw snap lines */}
        {
          // interactive && snapLines && snapLines.map((line, i) => (
          //   <line key={i} x1={line.x1} y1={line.y1} x2={line.x2} y2={line.y2} className="flow-snap-line"/>
          // ))
        }

        {/* Draw Links */}
        {
          renderLinks.map((link) => {
            let formattedID = formatLinkId(link);
            
            return <FlowLink 
              component_id={component_id}
              key={formattedID} 
              flowNodeLibrary={flowNodeLibrary}
              sourceNode={nodesRef.current.find(n => n.id === link.from.node_id)} 
              sourceOutput={link.from.output}
              targetNode={nodesRef.current.find(n => n.id === link.to.node_id)} 
              targetInput={link.to.input}
              selected={selectedLinks.includes(formattedID)}
              interactive={interactive}
              onSelect={handleLinkSelect}
              onDelete={handleLinkDelete}
              />
          })
        }


        {/* Draw Nodes */}
        {renderNodes.map((node) => {


          return <FlowNode 
            component_id={component_id}
            key={node.id} 
            node={node}
            nodeType={flowNodeLibrary.find(fn => fn.name === node.type)}
            flowNodeLibrary={flowNodeLibrary}
            nodes={nodesRef.current}
            links={linksRef.current}
            interactive={interactive}
            acceptableInputs={draftLink && draftLink.acceptableInputs ? draftLink.acceptableInputs : []}
            acceptableOutputs={draftLink && draftLink.acceptableOutputs ? draftLink.acceptableOutputs : []}
            selected={selectedNodes.includes(node.id)}
            inputDrafting={draftLink && draftLink.drafting && draftLink.startedBy === 'inputs' && draftLink.targetNode.id === node.id ? draftLink.targetInput : null}
            outputDrafting={(draftLink && draftLink.drafting && draftLink.startedBy === 'outputs' && draftLink.sourceNode.id === node.id) ? draftLink.sourceOutput : null}
            highlight={highlightedNodeIds.includes(node.id)}
            showPluginDropZone={isDraggingPlugin}
            highlightPluginDropZone={potentialDropZoneRef.current === node.id}
            onSelectNode={handleNodeSelect}
            onMouseDown={handleNodeMouseDown}
            onMouseUp={handleNodeMouseUp}
            onDragNodeStart={handleNodeDragStart}
            onDragNodeEnd={handleNodeDragEnd}
            onNodeChange={handleNodeChange}
            onLinkStart={handleLinkStart}
            onLinkEnd={handleLinkEnd}
            onPlay={handleNodePlay}
            onShowAdditional={handleNodeShowAdditional}
            />
        })}


        {/* Draw drag bounds as a rect */}
        {
          adjustedDragBounds && (
            <rect 
              x={adjustedDragBounds.x} 
              y={adjustedDragBounds.y} 
              width={adjustedDragBounds.width} 
              height={adjustedDragBounds.height} 
              className={"flow-drag-bounds " + (dragBoundsToDraw.width < 0 ? " flow-drag-bounds-crossing" : "")}/>
          )
        }
        

        {Object.entries(cursors).map(([id, cursor]) => (
          <FlowCursor key={id} cursor={cursor} />
        ))}


        {draftLink && draftLink.drafting && (
          <FlowLink 
            sourceNode={draftLink.sourceNode}
            sourceOutput={draftLink.sourceOutput}
            placeholderSourceX={draftLink.placeholderSourceX}
            placeholderSourceY={draftLink.placeholderSourceY}

            targetNode={draftLink.targetNode}
            targetInput={draftLink.targetInput}
            placeholderTargetX={draftLink.placeholderTargetX}
            placeholderTargetY={draftLink.placeholderTargetY}
            draft={true}

            flowNodeLibrary={flowNodeLibrary}
          />
        )}

        {
          draftLink && draftLink.temporaryTarget && draftLink.drafting && (
          <FlowLink 
            sourceNode={draftLink.sourceNode}
            sourceOutput={draftLink.sourceOutput}
            targetNode={draftLink.temporaryTarget.node}
            targetInput={draftLink.temporaryTarget.input}
            autocomplete={true}

            flowNodeLibrary={flowNodeLibrary}
          />
        )}

      </svg>

      <div className="flow-renderer-overlay">
        {
          false && <div className="flow-renderer-minimap">
            <div className="margin-bottom-05rem">
              <CustomButton
                display={<span>Zoom to Fit</span>}
                onClick={zoomToFit}
                block={true}
                color="grey"
                size="xs"
                />
            </div>
              
            <svg
              width="100"
              height="100"
              viewBox={`-5000 -5000 10000 10000`}
            >
              <rect 
                x={viewBoxX}
                y={viewBoxY}
                width={viewBoxWidth}
                height={viewBoxHeight}
                className="flow-renderer-minimap-viewbox"/>

              {/* Draw Nodes */}
              {renderNodes.map((node) => (
                <FlowNode 
                  key={node.id} 
                  node={node} 
                  interactive={false}
                  simplified={true}
                  selected={selectedNodes.includes(node.id)}
                  inputDrafting={draftLink && draftLink.drafting && draftLink.startedBy === 'inputs' && draftLink.targetNode.id === node.id ? draftLink.targetInput : null}
                  outputDrafting={(draftLink && draftLink.drafting && draftLink.startedBy === 'outputs' && draftLink.sourceNode.id === node.id) ? draftLink.sourceOutput : null}
                  highlight={highlightedNodeIds.includes(node.id)}
                  />
              ))} 

              {/* Draw Links */}
              {
                renderLinks.map((link) => {
                  let formattedID = formatLinkId(link);

                  // if(link.is_plugin_link) return null;
                  
                  return <FlowLink 
                    key={formattedID} 
                    sourceNode={nodesRef.current.find(n => n.id === link.from.node_id)} 
                    sourceOutput={link.from.output}
                    targetNode={nodesRef.current.find(n => n.id === link.to.node_id)} 
                    targetInput={link.to.input}
                    interactive={false}
                    flowNodeLibrary={flowNodeLibrary}
                    />
                })
              }
            </svg>
          </div>
        }
        
      </div>
    </div>
  );
}, (prevProps, nextProps) => {
  
  return diff(prevProps, nextProps) ? false : true;
});

export default FlowRenderer;
