import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import './Flow.scss';

import FlowNode from './FlowNode';

import {
  nodeWidth,
  calculateNodeHeight
} from './FlowBuilderUtils';

const FlowNodeThumbnail = ({nodeType, size = 50}) => {

  const svgRef = useRef(null);
  let padding = 10;
  const intelReducer = useSelector(state => state.intelReducer);

  let nodeTypes = intelReducer.flow_nodes || [];

  let height = calculateNodeHeight({type: nodeType.name}, nodeTypes);
  
  return ( <div className="flow-builder flow-node-thumbnail">
    <svg 
      ref={svgRef} 
      width={size} 
      height={size}
      viewBox={`-${padding} -${padding} ${nodeWidth + padding * 2} ${nodeWidth + padding * 2}`}
      >
      <FlowNode
        node={{
          type: nodeType.name,
          x: 0,
          y: nodeWidth / 2 - height / 2,
          settings: {}
        }}
        nodeType={nodeType}
        interactive={false}
        />
    </svg>
    </div>
  )
}

export default FlowNodeThumbnail;