// src/components/FlowBuilder/FlowLink.js
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { configCompatible } from 'utilities/typeCompatible';

import {
  showTooltip,
  hideTooltip
} from 'actions/actions.export';

import nodeCategories from 'configs/config.node-categories';

import {
  nodeHeaderHeight, 
  nodePaddingPerPort,
  nodePluginSpacing,
  nodeWidth,
  calculateNodeHeight,
  calculateHandleYValue,
  calculateControlPoints
} from './FlowBuilderUtils';

import './Flow.scss';

const FlowLink = React.memo(({ 
  component_id,
  sourceNode, 
  flowNodeLibrary = [],
  sourceOutput, 
  placeholderSourceX,
  placeholderSourceY,

  targetNode, 
  targetInput, 
  placeholderTargetX, 
  placeholderTargetY,

  draft = false,
  autocomplete = false,

  selected = false,
  onSelect,
  onDelete,

  interactive = true
}) => {

  const isPluginLink = sourceNode && sourceOutput === 'plugin_config' && targetNode && targetInput === 'plugins';

  const intelReducer = useSelector(state => state.intelReducer);
  const componentReducer = useSelector(state => state.componentReducer);

  if(!sourceNode && placeholderSourceX === undefined) return null;
  if(!targetNode && placeholderTargetX === undefined) return null;

  const sourceX = placeholderSourceX ? placeholderSourceX : sourceNode.x + nodeWidth;
  const sourceY = placeholderSourceY ? placeholderSourceY : calculateHandleYValue(sourceNode, flowNodeLibrary, sourceOutput, 'outputs') + sourceNode.y;

  const targetX = placeholderTargetX !== undefined ? placeholderTargetX : targetNode.x;
  const targetY = placeholderTargetY !== undefined ? placeholderTargetY : calculateHandleYValue(targetNode, flowNodeLibrary, targetInput, 'inputs') + targetNode.y;

  let formattedId; 
  let sourceId;
  if(sourceNode && targetNode){
    formattedId = sourceNode.id + ':' + sourceOutput + ' -> ' + targetNode.id + ':' + targetInput;
    sourceId = sourceNode.id + ':' + sourceOutput;
  }

  let compatible = 'compatible';
  let couldOutputNull = false;
  if(!placeholderSourceX && !placeholderSourceY && !placeholderTargetX && !placeholderTargetY){

    // lets do a type check between the source and target node ports using configCompatible
    let sourceConfig = flowNodeLibrary.find(n => n.name === sourceNode.type) ? flowNodeLibrary.find(n => n.name === sourceNode.type)['outputs']?.find(p => p.name === sourceOutput) : undefined;
    let targetConfig = flowNodeLibrary.find(n => n.name === targetNode.type) ? flowNodeLibrary.find(n => n.name === targetNode.type)['inputs']?.find(p => p.name === targetInput) : undefined;

    if(!sourceConfig || !targetConfig) return null;
    compatible = configCompatible(sourceConfig, targetConfig);

    if(sourceConfig.default === null) couldOutputNull = true;
  }

  let controlPoints = calculateControlPoints(sourceX, sourceY, targetX, targetY);

  let linkCacheValue = undefined;
  if(component_id && sourceNode){
    let nodeOutputCache = componentReducer.nodeOutputCache[component_id];
    if(nodeOutputCache && nodeOutputCache[sourceId] !== undefined){
      linkCacheValue = nodeOutputCache[sourceId];
    }
  }

  // Function to generate the 'd' attribute for the <path> element
  const generatePathData = (coords) => {
    if (coords.length === 0) return '';

    let pathData = `M ${coords[0].x},${coords[0].y}`;

    for (let i = 1; i < coords.length; i++) {
      const point = coords[i];
      pathData += ` L ${point.x},${point.y}`;
    }

    return pathData;
  };

  let midPoint = controlPoints[Math.floor(controlPoints.length / 2)];
  
  if(isPluginLink){
    
    return (<g className={
      "flow-plugin-link "
    }>
      <rect 
        x={sourceNode.x + nodePluginSpacing / 2} 
        y={targetNode.y} 
        width={nodeWidth - nodePluginSpacing} 
        height={sourceNode.y - targetNode.y}
        className="flow-plugin-link__rect"
        />
    </g>)
  };

  return (<g className={
      "flow-link " + 
      (draft ? "flow-link__draft" : "") + 
      (autocomplete ? "flow-link__autocomplete" : "") +
      (compatible === 'compatible' ? " flow-link__compatible" : compatible === 'maybe' ? " flow-link__maybe" : " flow-link__incompatible") +
      (selected ? " flow-link__selected" : "") +
      (couldOutputNull ? " flow-link__maybe-null" : "") +
      (interactive == false ? " no-pointer-events" : "") + 
      (linkCacheValue !== undefined ? " flow-link__cached" : "")
      } onClick={e => {
        onSelect(formattedId)
      }}>
      {/* <line x1={sourceX} y1={sourceY} x2={targetX} y2={targetY} /> */}
      <path d={generatePathData(controlPoints)} />

      { !autocomplete &&
        <path d={generatePathData(controlPoints)} className="flow-link-selector-path"/>
      }

      {/* add an arrow at the end of the path */}
      {
        autocomplete || draft &&
        <g className="flow-link-arrow">
          <polygon points={`${targetX},${targetY} ${targetX - 10},${targetY - 5} ${targetX - 10},${targetY + 5}`} />
        </g>
      }

      {/* Trash button if selected */}
      {
        (selected && onDelete) &&
        <g className="flow-link-button">
          <foreignObject x={midPoint.x - 20} y={midPoint.y - 20} width="40" height="40" className="flow-link-button-wrapper">
            <div className="flow-link-button-inner">
              <span className="flow-link-button__button" onMouseDown={(e) => {
                console.log('delete', formattedId);
                if(onDelete) onDelete(formattedId);
              }}>
                <i className="fas fa-trash text-danger fa-fw"></i>
              </span>
            </div>
          </foreignObject>
        </g> 
      }
      
    </g>);
})


export default FlowLink;