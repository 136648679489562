import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import shortid from 'shortid'

import {
  tryToDemoComponent,
  stopDemoComponent,
  removeDemoCacheItem,
  removeDemoCacheAfterIndex,
  showTooltip,
  hideTooltip
} from 'actions/actions.export'

import convertMessageCacheToArray from 'utilities/convertMessageCacheToArray'

import Hydrate from 'components/Hydrate/Hydrate'
import MegaMarkdown from 'components/MegaMarkdown/MegaMarkdown'
import CodeHighlighter from 'components/CodeHighlighter/CodeHighlighter'

import Modal from 'kit/components/Modal/Modal'
import CustomField from 'kit/components/CustomField/CustomField'

import './BetterChat.scss';

// a basic react class component
class BetterChat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: shortid.generate(),
      message: "",
      function_responses: [],
      lastResponseTimestamp: new Date().toISOString(),
      showBackOfHouse: false,
      showBackOfHouseData: {}
    };
  }

  scrollToBottom = () => {
    var objDiv = document.getElementById("better-chat-messages-inner-" + this.state.id);
    if(objDiv) objDiv.scrollTop = objDiv.scrollHeight;
  }

  componentWillReceiveProps(nextProps) {
    // we need to check to see if we should scroll to the bottom of the chat, based on a new response
    if(nextProps.demoCache && nextProps.demoCache.length > 0) {
      let lastResponse = nextProps.demoCache[nextProps.demoCache.length - 1].response;

      if(lastResponse && lastResponse.output_data && lastResponse.output_data.timestamp > this.state.lastResponseTimestamp) {
        setTimeout(() => {
          this.setState({ lastResponseTimestamp: lastResponse.output_data.timestamp });
          this.scrollToBottom();
        }, 100);
      }
    }
  }

  handleStopDemoComponent = (e) => {
    if(e){
      if(e.preventDefault) {
        e.preventDefault();
      }
    }
    this.props.dispatch(stopDemoComponent({
      id: this.props.component_id,
      version: this.props.version
    }));
  }

  handleRetryMessage = () => {
    let messages = convertMessageCacheToArray(this.props.demoCache);

    this.props.dispatch(tryToDemoComponent({
      id: this.props.component_id,
      version: this.props.version,
      data: {
        data: {
          variables: {
            ...this.props.variables
          },
          messages: messages
        },
        stream: true
      }
    }, true, this.props.publicDemo))
  }

  handleSendMessage = (e, message) => {
    if(e){
      if(e.preventDefault) {
        e.preventDefault();
      }
    }
    
    // assemble a new messages array using the props.demoCache array and the new message
    let newMessages = [];

    if(this.props.demoCache) {
      newMessages = convertMessageCacheToArray(this.props.demoCache);
    }

    if(this.state.function_responses.length > 0) {
      newMessages.push({
        role: "tool",
        function_responses: this.state.function_responses
      });
    } else {
      newMessages.push({
        role: "user",
        content: message || this.state.message,
      });
    }

    this.props.dispatch(tryToDemoComponent({
      id: this.props.component_id,
      version: this.props.version,
      data: {
        data: {
          variables: {
            ...this.props.variables
          },
          messages: newMessages
        },
        stream: true
      }
    }, true, this.props.publicDemo))
    
    this.setState({ message: "" , function_responses: []});
    this.scrollToBottom();
  }

  render() {
    const { userReducer, componentReducer, component, dispatch } = this.props;

    if(!component) {
      return null;
    }

    let messages = [];
    if(this.props.demoCache) {
      messages = convertMessageCacheToArray(this.props.demoCache);
    }


    // check to see if the last message has function_calls
    let hasFunctionCallsWaiting = messages.length > 0 && messages[messages.length - 1].function_calls && messages[messages.length - 1].function_calls.length > 0;

    let everyFunctionHasResponse = true;
    if(hasFunctionCallsWaiting) {
      everyFunctionHasResponse = messages[messages.length - 1].function_calls.every((call, index) => {
        return this.state.function_responses[index] && this.state.function_responses[index].response.trim() !== "";
      });
    }

    // if hideCompletedFunctionCalls is true, remove any function_calls that have a response
    if(this.props.hideCompletedFunctionCalls) {
      // this is determined by hiding any function call that isn't the most recent message and hiding all function response messages
      messages = messages.filter((message, index) => {
        if(message.function_calls && message.function_calls.length > 0) {
          if(index === messages.length - 1) {
            return true;
          } else {
            return false;
          }
        } else if(message.function_responses && message.function_responses.length > 0) {
          return false;
        } else {
          return true;
        }
      });
    }

    return (
      <div className="better-chat">

        <Modal
          show={this.state.showBackOfHouse}
          maxWidth={600}
          exitable={true}
          hideExitButton={true}
          onExit={() => this.setState({showBackOfHouse: false})}
          content={<div>
            <CodeHighlighter
              language="json"
              code={JSON.stringify(this.state.showBackOfHouseData, null, 2)}
              collapsedJSONDepth={2}
              />
          </div>}
          />
        <div className="better-chat-messages">
          <div className="better-chat-messages-inner" id={"better-chat-messages-inner-" + this.state.id}>
            {messages.map((message, index) => (
              <div key={index} className="better-chat-message">
                <div className="better-chat-message-avatar">
                  { 
                    (message.role === "user" || message.role === "tool") ? 
                    <Hydrate type="user" id={userReducer.myData ? userReducer.myData.id : ""} mode="avatar" size={25}/>
                    : 
                    <Hydrate type="components" id={component.id} mode="avatar" size={25}/>
                  }
                </div>
                <div className="better-chat-message-body">
                  <div className="list-left list-left-align-flex-end better-chat-message-role-line">
                    <div className="better-chat-message-role">
                      { 
                        message.role === "user" ? 
                        (userReducer.myData ? userReducer.myData.display_name : "You")
                        : 
                        message.role === "agent" ?
                        component.display_name
                        :
                        message.role === "tool" &&
                        "Simulated Tool Response"
                      }
                    </div>
                    {
                      message.timestamp &&
                      <div className="better-chat-message-timestamp">
                        {
                          moment(message.timestamp).fromNow()
                        }
                      </div>
                    }
                  </div>
                  <div className="better-chat-message-content">
                    {message.content && 
                      <MegaMarkdown text={message.content.trim()} />
                    }
                    {
                      message.function_responses && message.function_responses.length > 0 &&
                      message.function_responses.map((response, responseIndex) => (
                        <div key={responseIndex} className="box box-light-border better-chat-message-function-response">
                          <div className="better-chat-message-function-response-title margin-bottom-1rem">
                            <h5 className="no-margin"><code>{response.name}()</code></h5>
                          </div>
                          <hr/>
                          <div className="better-chat-message-function-response-content">
                            <div className="margin-bottom-05rem">Response</div>
                            <CodeHighlighter
                              language="json"
                              code={response.response}
                              collapsedJSONDepth={2}
                              />
                          </div>
                        </div>
                      ))
                    }
                    {
                      message.function_calls && message.function_calls.length > 0 && 
                      message.function_calls.map((call, callIndex) => (

                        <div key={callIndex} className="box box-light-border better-chat-message-function-call">
                          <div className="better-chat-message-function-call-title margin-bottom-1rem">
                            <h5 className="no-margin"><code>{call.name}()</code></h5>
                          </div>
                          <hr/>
                          <div className="better-chat-message-function-call-content">
                            <div className="margin-bottom-05rem">Arguments</div>
                            <CodeHighlighter
                              language="json"
                              code={JSON.stringify(call.args, null, 2)}
                              collapsedJSONDepth={2}
                              />
                          </div>
                          {
                            // if this is the most recent message show the response field
                            index === messages.length - 1 &&
                            <CustomField
                              label="Response"
                              rows={3}
                              placeholder="Provide a response to this function call to continue the conversation..."
                              value={this.state.function_responses[callIndex]?.response || ""}
                              onChange={e => {
                                let newResponses = [...this.state.function_responses];
                                if(newResponses.length === 0) {
                                  newResponses = message.function_calls.map((nc) =>{
                                    return {
                                      name: nc.name,
                                      response: ""
                                    }
                                  })
                                }
                                newResponses[callIndex].response = e.value;
                                this.setState({ function_responses: newResponses });
                              }}
                              />
                            }
                        </div>
                      ))
                    }
                  </div>
                  
                  {
                    !message.isInProgress &&      
                    <div className="better-chat-message-actions">
                      <i className="fal fa-copy fa-fw" 
                        onClick={e => {
                          dispatch(hideTooltip());
                          if(navigator.clipboard) {
                            navigator.clipboard.writeText(message.content);
                          }
                        }}
                        onMouseEnter={e => {
                          dispatch(showTooltip({
                            el: e.target,
                            lag: 250,
                            content: <span>
                              Copy Text
                            </span>,
                            position: 'bottom'
                          })
                          )
                        }}
                        onMouseLeave={e => {
                          dispatch(hideTooltip());
                        }}
                        />
                      <i className="fal fa-trash fa-fw" 
                        onClick={e => {
                          dispatch(hideTooltip());
                          dispatch(removeDemoCacheItem({
                            id: this.props.component_id,
                            version: this.props.version,
                            index: message.cacheIndex,
                            whichHalf: message.role === "user" ? "request" : "response"
                          }));
                        }}
                        onMouseEnter={e => {
                          dispatch(showTooltip({
                            el: e.target,
                            lag: 250,
                            content: <span>
                              Delete
                            </span>,
                            position: 'bottom'
                          })
                          )
                        }}
                        onMouseLeave={e => {
                          dispatch(hideTooltip());
                        }}
                        />
                      {
                        message.role === "user" &&
                        <i className="fal fa-fast-backward fa-fw"
                          onClick={e => {
                            dispatch(hideTooltip());
                            // move this message's content to this.state.message
                            this.setState({ message: message.content });

                            // remove all messages after this one including this one
                            dispatch(removeDemoCacheAfterIndex({
                              id: this.props.component_id,
                              version: this.props.version,
                              index: message.cacheIndex
                            }));
                          }}

                        onMouseEnter={e => {
                          dispatch(showTooltip({
                            el: e.target,
                            lag: 250,
                            content: <span>
                              Rewind to Here
                            </span>,
                            position: 'bottom'
                          })
                          )
                        }}
                        onMouseLeave={e => {
                          dispatch(hideTooltip());
                        }}
                          />
                      }
                      {
                        (message.role === "agent" && index === messages.length - 1) &&
                        <i className="fal fa-sync fa-fw"
                          onClick={ e => {
                            dispatch(hideTooltip());

                            // remove the response half of this message
                            dispatch(removeDemoCacheItem({
                              id: this.props.component_id,
                              version: this.props.version,
                              index: message.cacheIndex,
                              whichHalf: "response"
                            }));

                            // grab the request half of this message
                            let requestMessage = messages[index - 1];
                            

                            // remove the request half of this message
                            dispatch(removeDemoCacheItem({
                              id: this.props.component_id,
                              version: this.props.version,
                              index: requestMessage.cacheIndex,
                              whichHalf: "request"
                            }));

                            // set the request half of this message as the new message
                            this.handleSendMessage(e, requestMessage.content);
                          }}
                          onMouseEnter={e => {
                            dispatch(showTooltip({
                              el: e.target,
                              lag: 250,
                              content: <span>
                                Regenerate
                              </span>,
                              position: 'bottom'
                            })
                            )
                          }}
                          onMouseLeave={e => {
                            dispatch(hideTooltip());
                          }}
                          />
                      }
                      {
                        message.role === "agent" &&
                        <i className="fal fa-code fa-fw"
                          onClick={e => {
                            // open a model with the JSON data for this full message
                            
                            // find the original response from demoCache based on this message's cacheIndex
                            let data = this.props.demoCache.find((m, demoIndex) => demoIndex === message.cacheIndex).response;

                            this.setState({
                              showBackOfHouse: true,
                              showBackOfHouseData: data
                            });

                            dispatch(hideTooltip());
                          }}
                          onMouseEnter={e => {
                            dispatch(showTooltip({
                              el: e.target,
                              lag: 250,
                              content: <span>
                                View API Packet
                              </span>,
                              position: 'bottom'
                            })
                            )
                          }}
                          onMouseLeave={e => {
                            dispatch(hideTooltip());
                          }}
                          />
                      }
                      {
                        // message.role === "agent" &&
                        // <i className="fal fa-thumbs-up fa-fw"

                        //   onMouseEnter={e => {
                        //     dispatch(showTooltip({
                        //       el: e.target,
                        //       lag: 250,
                        //       content: <span>
                        //         Give Feedback
                        //       </span>,
                        //       position: 'bottom'
                        //     })
                        //     )
                        //   }}
                        //   onMouseLeave={e => {
                        //     dispatch(hideTooltip());
                        //   }}
                        //   />
                      }
                      {
                        // message.role === "agent" &&
                        // <i className="fal fa-thumbs-down fa-fw"

                        //   onMouseEnter={e => {
                        //     dispatch(showTooltip({
                        //       el: e.target,
                        //       lag: 250,
                        //       content: <span>
                        //         Give Feedback
                        //       </span>,
                        //       position: 'bottom'
                        //     })
                        //     )
                        //   }}
                        //   onMouseLeave={e => {
                        //     dispatch(hideTooltip());
                        //   }}
                        //   />
                      }
                    </div>
                  }
                </div>
              </div>
            ))}

          </div>
          {
            this.props.publicDemo &&
            <div className="better-chat-warning">
              AI can make mistakes, verify responses.Do not share sensitive information.
            </div>
          }
        </div>
        <form className={"better-chat-input " + (hasFunctionCallsWaiting ? "better-chat-input__disabled" : "")} onSubmit={this.handleSendMessage}>
          <textarea
            type="text"
            value={hasFunctionCallsWaiting ? "" : this.state.message}
            placeholder={hasFunctionCallsWaiting ? 
              everyFunctionHasResponse ?
              "Ready to send, click the arrow to continue..."
              :
              "Please provide simulated responses to the agent's function calls before proceeding..." 
              : 
              "Chat with this agent..."}
            onChange={e => this.setState({ message: e.target.value })}
            disabled={hasFunctionCallsWaiting}
            
            onKeyDown={e => {
              // if enter key and NOT shift key
              if(e.keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                this.handleSendMessage();
              }
            }}
          />
          <button 
            className={"better-chat-send " + (hasFunctionCallsWaiting && !everyFunctionHasResponse ? "better-chat-send__disabled" : "")}
            type="submit"
            disabled={hasFunctionCallsWaiting && !everyFunctionHasResponse}
            onMouseEnter={e => this.setState({ hoveringSubmit: true })}
            onMouseLeave={e => this.setState({ hoveringSubmit: false })}
            onClick={e => {
              if(e.preventDefault) {
                e.preventDefault();
              }
              if(componentReducer.tryingToDemoComponent) {
                this.handleStopDemoComponent(e);
              } else {
                this.handleSendMessage(e)
              }
            }}>
              {
                componentReducer.tryingToDemoComponent ?
                (
                  this.state.hoveringSubmit ?
                  <i className="far fa-stop-circle"></i>  
                  :  
                  <i className="fad fa-spinner-third fa-spin"></i>
                )
                :
                <i className="fas fa-arrow-up"></i>
              }
            </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { userReducer, guiReducer, componentReducer, sharedReducer } = state;

  return {
    userReducer,
    componentReducer,
    sharedReducer,
    guiReducer
  }
}

export default connect(mapStateToProps)(BetterChat);

  